import React, { useState, useEffect } from 'react';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios, {baseURL} from './../utils/axiosConfig';  
const HealthDetail = () => {
  const { id } = useParams();  // Récupérer l'ID depuis les paramètres d'URL
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();



  // Récupérer les données de l'article spécifique
  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/informations_sanitaires/${id}`);
        setArticle(response.data);  // Assurez-vous que les données soient dans le bon format
        setLoading(false);
      } catch (err) {
        setError('Une erreur s\'est produite lors du chargement de l\'article.');
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return <div>Chargement de l'article...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!article) {
    return <div>Article non trouvé</div>;
  }
   


  return (
    <>
      <NavBar />
      <div id="main" style={{ height: '100%' }}>
        <div className="container mb-3 mt-3 pt-2" >
          <div className="d-flex align-items-center mt-4 mb-5"  >
            <Link to="/healthInfo" className="pe-3 d-inline-block" onClick={() => navigate(`/healthInfo/`)}>
              <i className="fas fa-arrow-left fs-6 apropos" style={{marginTop:"8rem",fontWeight:'bold', color:'#ffa500', cursor:'pointer'}}></i>
            </Link>
            <h1 className="fw-bolder h2 mb-0 apropos" style={{marginTop:"8rem",fontWeight:'bold', color:'#ffa500'}}>{article.titre}</h1>
          </div>
          <h2 className="fs-7 mb-2 fw-lighter mb-2 mb-lg-4" style={{"color":"#249646", "fontWeight":"bold"}}>
            Catégorie : {article.Category.nom}
          </h2>

          <h2 className="fs-7 mb-2 fw-lighter mb-2 mb-lg-4" style={{"color":"#249646", "fontWeight":"bold"}}>
            Source : {article.source}
          </h2>

          <div className="bg-image mb-4">
            <img
              src={`${baseURL}${article.image_url}`}  // Utilisation du baseUrl pour les images
              className="w-100 rounded img-fluid"
              alt={article.titre}
              title={article.titre}
            />
          </div>

          <div className="py-2 py-lg-4">
            <div dangerouslySetInnerHTML={{__html: article.contenu, }}></div>
          </div>
        </div>
     
    
      </div>
      <Footer />
    </>
  );
};

export default HealthDetail;
