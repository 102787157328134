import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Toast } from 'primereact/toast';
import NavBar from '../partials/nav';
import Footer from '../partials/footer';

const Feedback = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const toast = React.useRef(null);

    // Fonction pour récupérer les feedbacks
    const fetchFeedbacks = async () => {
        try {
            const response = await axios.get('/api/feedbacks/sorted'); // Remplace par ton endpoint
            setFeedbacks(response.data);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Impossible de récupérer les feedbacks', life: 3000 });
        }
    };

    // Fonction pour liker un feedback
    const handleLike = async (id, reactionType) => {
        try {
            await axios.post(`/api/feedbacks/${id}/like`, { type: reactionType }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Réaction enregistrée!', life: 3000 });
            // Actualiser les feedbacks après la réaction
            fetchFeedbacks();
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Impossible de réagir au feedback', life: 3000 });
        }
    };

    useEffect(() => {
        fetchFeedbacks();
    }, []);

    return (
        <div className="container pt-4">
            <Toast ref={toast} />
            <h2 className="fs-4 fw-bolder mb-3">Feedbacks</h2>
            <div className="row">
                {feedbacks.map((feedback) => (
                    <div className="col-md-6 mb-4" key={feedback.id}>
                        <Card className="h-100">
                            <div className="d-flex align-items-center">
                                <Avatar icon={<i className="fas fa-user-alt"></i>} />
                                <div className="ms-2">
                                    <p className="mb-1">{feedback.message}</p>
                                    <div className="reactions">
                                        <div dangerouslySetInnerHTML={{ __html: feedback.experience }}></div>
                                        <div className="reaction-options d-flex">
                                            <Button
                                                rounded
                                                severity="success"
                                                outlined
                                                key="like"
                                                icon="pi pi-thumbs-up"
                                                label={feedback.likeCount}
                                                className="p-button-sm p-button-secondary me-2"
                                                onClick={() => handleLike(feedback.id, 'like')}
                                            />
                                            <Button
                                                rounded
                                                severity="success"
                                                outlined
                                                key="love"
                                                icon="pi pi-heart"
                                                label={feedback.loveCount}
                                                className="p-button-sm p-button-secondary me-2"
                                                onClick={() => handleLike(feedback.id, 'love')}
                                            />
                                            <Button
                                                rounded
                                                severity="success"
                                                outlined
                                                key="haha"
                                                icon="pi pi-circle"
                                                label={feedback.loughCount}
                                                className="p-button-sm p-button-secondary me-2"
                                                onClick={() => handleLike(feedback.id, 'lough')}
                                            />
                                            {/* 
                                            {/* <Button
                                                        rounded 
                                                        severity="success"
                                                        outlined
                                                        key='lough'
                                                        // label={reaction.charAt(0).toUpperCase() + reaction.slice(1)}
                                                        icon={`pi pi-circle`}
                                                        className="p-button-sm p-button-secondary me-1"
                                                        onClick={() => handleLike(feedback.id, 'lough')}
                                                    />
                                                    <Button
                                                        rounded 
                                                        severity="success"
                                                        outlined
                                                        key='Pround'
                                                        // label={reaction.charAt(0).toUpperCase() + reaction.slice(1)}
                                                        icon={`pi pi-discord`}
                                                        className="p-button-sm p-button-secondary me-1"
                                                        onClick={() => handleLike(feedback.id, 'Pround')}
                                                    /> 
                                                    <Button
                                                        rounded 
                                                        severity="success"
                                                        outlined
                                                        key='NotLike'
                                                        // label={reaction.charAt(0).toUpperCase() + reaction.slice(1)}
                                                        icon={`pi pi-thumbs-down`}
                                                        className="p-button-sm p-button-secondary me-1"
                                                        onClick={() => handleLike(feedback.id, 'NotLike')}
                                                    /> */}
                                            <Button
                                                rounded
                                                severity="success"
                                                outlined
                                                key="sad"
                                                icon="pi pi-discord"
                                                label={feedback.proundCount}
                                                className="p-button-sm p-button-secondary me-2"
                                                onClick={() => handleLike(feedback.id, 'Pround')}
                                            />
                                            <Button
                                                rounded
                                                severity="success"
                                                outlined
                                                key="angry"
                                                icon="pi pi-thumbs-down"
                                                label={feedback.notLikeCount}
                                                className="p-button-sm p-button-secondary me-2"
                                                onClick={() => handleLike(feedback.id, 'NotLike')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
};

const Page = () => {
    return (
        <>
            <NavBar />
            <div id="main" style={{ height: '100%' }}>
                <div className="bg-light text-dark px-2 py-3 p-lg-4" style={{ marginTop: '8rem' }}>
                    <div className="container">
                        <h2 className="fs-4 fw-bolder mb-3">Les témoignages sur l'assistance sur YohedaCare</h2>
                    </div>
                </div>

                <div className="container pt-0 pb-5">
                    <Feedback />
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Page;
