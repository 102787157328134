import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';      
import { InputText } from 'primereact/inputtext';  
import { Password } from 'primereact/password';  
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';

const Login = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errors, setErrors] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const toast = React.useRef(null);


  useEffect(()=>{
    const verifyLogged=()=>{
      // verify if the user is already authenticated and can get its informations
    const userInformations = JSON.parse(localStorage.getItem('user_informations'));
    if(userInformations && localStorage.getItem('token')){
      axiosInstance.get('/user', 
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
       ).catch((err)=>{
        console.log(err);
        return;
       })
      // const userRole = userInformations.role;
      // if (userRole === 'admin') {
      //   navigate('/admin'); // Redirect to admin page
      // } else if (userRole === 'Medecin') {
      //   navigate('/appointments'); // Redirect to doctor dashboard
      // } else if (userRole === 'Patient') {
      //   navigate('/assistance'); // Redirect to patient dashboard
      // } else {
      //   navigate('/home'); // Default redirect
      // }
    }
    }
    verifyLogged();
  }, []);


  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors('');

    try {
      const response = await axiosInstance.post('/login', {
        email: email,
        mot_de_passe: password
      });

      console.log(response.data);
      const userInfo = response.data.user;
      const userRole = userInfo.role; // Retrieve user role

      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user_informations', JSON.stringify(userInfo));

      // Redirect based on user role
      if (userRole === 'admin') {
      localStorage.setItem('admin_user_inf', JSON.stringify(userInfo));
      navigate('/admin'); // Redirect to admin page
      } else if (userRole === 'Medecin') {
        navigate('/appointments'); // Redirect to doctor dashboard
      } else if (userRole === 'Patient') {
        navigate('/assistance'); // Redirect to patient dashboard
      } else {
        navigate('/home'); // Default redirect
      }

      toast.current.show({ severity: 'success', summary: 'Connexion réussie', detail: 'Vous êtes maintenant connecté.', life: 3000 });

    } catch (error) {
      console.error('Error during login:', error);
      if (error?.response?.data) {
        setErrors(error.response.data.error);
      } else {
        setErrors("Quelque chose s'est mal passé.");
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
       <NavBar/>
      <div id="main" style={{ height: '100%' }}>
           
        <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5 apropos" style={{ maxWidth: '480px', minHeight: '100vh', marginTop:'9.6rem' }}>
          <div className="container">
            <div className="card bg-light text-dark" style={{ borderRadius: '0' }}>
              <div className="card-body p-4 p-md-5 text-center">
                <div className="mb-4 mt-4 mb-md-5 mt-md-5 pb-3 pb-md-5">
                  <h1 className="h2 fw-bold mb-2 text-uppercase" style={{fontWeight:'bold', color:'#ffa500'}}>Se connecter</h1>
                  <p className="text-dark-50 mb-5">Merci de renseigner les champs suivants</p>
                  <form id="form-register" method="post">
                    <div className="mb-4" style={{ width: "71%", marginLeft: "3rem" }}>
                      <InputText  
                        type="email"
                        id="register-email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className="form-control form-control-lg"
                        placeholder="Entrez votre email *"
                      />
                    </div>
                    <div className="mb-4 w-100" style={{ marginLeft: "3rem" }}>
                      <Password
                        id="register-password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-100"
                        placeholder="Entrez votre mot de passe *"
                        toggleMask
                        feedback={false}
                      />
                    </div>
                    <div className="form-check text-start mb-4" style={{ marginLeft: "3rem" }}>
                      <input className="form-check-input me-0" type="checkbox" value="1" name="cgv" id="register-cgv" />
                      <label className="form-check-label" htmlFor="register-cgv">Me souvenir de moi</label>
                    </div>
                    
                    <p className="mb-4 mr-4" style={{marginLeft: '-4.7rem'}}>
                      <Link to="/forgot-password">
                        <i className="pi pi-lock" style={{ marginRight: '0.5rem' }}></i>
                        Mot de passe oublié ?
                      </Link>
                    </p>
               
                    {loading && <ProgressSpinner style={{ width: '50px', height: '50px' }} />}
                    {errors && <p style={{ color: 'red' }}>{errors}</p>}
                    <Toast ref={toast} />
                    <Button
                      label="Se connecter"
                      className="p-button p-button-secondary p-button-lg"
                      onClick={submitForm}
                      disabled={loading}
                    />
                  </form>
                </div>
                <div>
                  <p className="mb-0">Vous n'avez pas un compte ? <Link to="/register">Créer compte</Link></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
