import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axiosInstance, { baseURL } from '../utils/axiosConfig';
import NavBar from '../partials/nav';
import Footer from '../partials/footer';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const PartenairesVille = () => {
  const { ville } = useParams();  // Récupère 'ville' depuis l'URL

  const [partenaires, setPartenaires] = useState([]);
  const [filteredPartenaires, setFilteredPartenaires] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); 

  useEffect(() => {
    console.log("Ville:", ville); // Déboguer la valeur de ville
  
    if (!ville) {
      setError('Ville non définie');
      return;
    }
  
    const fetchPartenairesVille = async () => {
      try {
        const response = await axiosInstance.get(`/ville/${ville}/`);
        setPartenaires(response.data);
        setFilteredPartenaires(response.data);
      } catch (error) {
        setError('Erreur lors de la récupération des partenaires');
      } finally {
        setLoading(false);
      }
    };
  
    fetchPartenairesVille();
  }, [ville]);
  
  // Filtrage des partenaires en fonction du terme de recherche
  const filterPartenaires = (term) => {
    if (!term) {
      setFilteredPartenaires(partenaires); 
    } else {
      const lowerCaseTerm = term.toLowerCase();
      const filtered = partenaires.filter((partenaire) =>
        partenaire.nom.toLowerCase().includes(lowerCaseTerm) ||
        partenaire.description.toLowerCase().includes(lowerCaseTerm)
      );
      setFilteredPartenaires(filtered);
    }
  };

  // Gestion du changement de la barre de recherche
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterPartenaires(term);
  };

  const partenaireTemplate = (partenaire) => (
    <div className="book-item">
      <a href={partenaire.link} className="book-link">
        <img src={`${baseURL}/${partenaire.photo}`} className="book-image" alt={partenaire.nom}  />
      </a>
      <div className="book-info">
        <h2 className="book-name">
          <span className="text-dark" style={{ fontSize: '1rem', fontWeight: 'bolder' }}>
            {partenaire.nom} ({partenaire.sigle})
          </span>
        </h2>
        <div className="book">Sigle : {partenaire.sigle}</div>
        <div className="book">Leader : {partenaire.representant}</div>
        <div className="book-description">Description : {partenaire.description}</div>
        <div>
          <a href={partenaire.link} title={partenaire.nom} style={{ color: "green", textDecoration: 'none' }}>
            Contact : {partenaire.numero}
          </a>
        </div>
        <div className="fake-line-4">
          <Link to={`/villeinfo/${ville}`} title={partenaire.nom} className="fs-7" style={{ display: 'block', marginTop: '1rem' }}>
            En savoir plus
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <NavBar />
      <div className="container apropos" style={{marginTop:'10rem'}}>
        <h3 className="fs-4 fw-bolder mb-3" style={{fontWeight:'bold', color:'#ffa500'}}>Notre réseau de {ville}</h3>

        {/* Barre de recherche */}
        <div className="mb-4">
          <FloatLabel>
            <InputText
              id="search-partenaires"
              value={searchTerm}
              onChange={handleSearchChange}
              className="form-control form-control-lg"
              autoComplete="off"
              style={{ width: '100%' }}
            />
            <label htmlFor="search-partenaires">Rechercher une association</label>
          </FloatLabel>
        </div>

        {loading ? (
          <p>Chargement des partenaires...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div className="books-container">
            {filteredPartenaires.length > 0 ? (
              filteredPartenaires.map((partenaire, index) => (
                <div key={index} className="book-item-wrapper">
                  {partenaireTemplate(partenaire)}
                </div>
              ))
            ) : (
              <p>Aucun partenaire trouvé dans cette ville.</p>
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PartenairesVille;
