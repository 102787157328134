import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import axiosInstance from '../utils/axiosConfig'; // Ton instance Axios
import { Toast } from 'primereact/toast';
import HeaderAdmin from '../partials/header-admin';
import AdminFooter from '../partials/admin-footer';

const RendezVousNonTraites = () => {
    const [rdvs, setRdvs] = useState([]);
    const [selectedRdvs, setSelectedRdvs] = useState([]);
    const toast = useRef(null);

    // Fonction pour récupérer les rendez-vous non traités
    const fetchRendezVous = async () => {
        try {
            const response = await axiosInstance.get('/rendezvous/non-traiter', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            setRdvs(response.data);
        } catch (error) {
            console.error("Erreur lors de la récupération des rendez-vous:", error);
        }
    };

    // Fonction pour envoyer une demande de feedback
    const sendFeedbackRequest = async () => {
        if (selectedRdvs.length > 0) {
            try {
                const rdvIds = selectedRdvs.map(rdv => rdv.id);

                // Envoi de la requête à l'API pour créer les notifications
                await axiosInstance.post('/feedback/notifications', { rdvIds }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                });

                toast.current.show({ severity: 'success', summary: 'Feedback envoyé', detail: 'Les demandes de feedback ont été envoyées.', life: 3000 });

                // Actualiser les rendez-vous après l'envoi des feedbacks
                fetchRendezVous();
            } catch (error) {
                console.error("Erreur lors de l'envoi des demandes de feedback:", error);
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'envoi des demandes de feedback.', life: 3000 });
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Aucune sélection', detail: 'Veuillez sélectionner au moins un rendez-vous.', life: 3000 });
        }
    };

    useEffect(() => {
        fetchRendezVous();
    }, []);

    return (
        <div className="app-wrapper">
            <HeaderAdmin />
            <main className="app-main">
                <div className="app-content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h3 className="mb-0">Les Rendez-vous Non Traités</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="app-content">
                    <Toast ref={toast} />

                    {/* Tableau des rendez-vous */}
                    <div className="datatable-responsive-demo">
                        <DataTable 
                            value={rdvs} 
                            selection={selectedRdvs} 
                            onSelectionChange={(e) => setSelectedRdvs(e.value)}
                            paginator rows={10} 
                            rowsPerPageOptions={[5, 10, 25]} 
                            className="p-datatable-responsive" 
                            header="Liste des Rendez-vous Non Traités"
                            responsiveLayout="scroll"
                            selectionMode="checkbox"
                        >
                            {/* Colonnes de la table */}
                            <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                            <Column field="Medecin.Utilisateur.nom" header="Médecin" sortable></Column>
                            <Column field="Patient.Utilisateur.nom" header="Patient" sortable></Column>
                            <Column field="date_heure" body={(rowData) => `${new Date(rowData.date_heure).toDateString("fr-FR")}`} header="Date du Rendez-vous" sortable></Column>
                            <Column field="date_heure_fin" body={(rowData) => `${new Date(rowData.date_heure_fin).toLocaleTimeString("fr")}`} header="Heure du Rendez-vous" sortable></Column>
                        </DataTable>
                    </div>

                    {/* Bouton pour envoyer la demande de feedback */}
                    <div className="mt-4">
                        <Button label="Envoyer Demande de Feedback" icon="pi pi-send" className="p-button-success" onClick={sendFeedbackRequest} />
                    </div>
                </div>
            </main>
            <AdminFooter />
        </div>
    );
};

export default RendezVousNonTraites;
