import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import HeaderAdmin from "../partials/header-admin";
import AdminFooter from "../partials/admin-footer";
import axios from "../utils/axiosConfig";
import ApexCharts from 'react-apexcharts'; // Importer ApexCharts
import { Dialog } from 'primereact/dialog'; // Importer Dialog pour les modals
import { Button } from 'primereact/button'; // Importer Button pour les actions
import { DataTable } from 'primereact/datatable'; // Importer DataTable pour l'affichage des listes
import { Column } from 'primereact/column'; // Importer Column pour définir les colonnes des DataTable
import 'primeicons/primeicons.css';
import { Rating } from 'primereact/rating';
import { Toast } from "primereact/toast";

export default function AdminPage() {
    const [visitors, setVisitors] = useState([]);
    const [users, setUsers] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [feedbacks, setFeedbacks] = useState([]);
    const [rdv, setRdvs] = useState([]);
    const [visitData, setVisitData] = useState([]);
    const [visitDates, setVisitDates] = useState([]);
    const [visibleUsers, setVisibleUsers] = useState(false); // État du modal Utilisateurs
    const [visibleDoctors, setVisibleDoctors] = useState(false); // État du modal Médecins
    const [visibleFeedbacks, setVisibleFeedbacks] = useState(false); // État du modal Feedbacks
    const token = localStorage.getItem('token');
    const toast = useRef(null);

        useEffect(() => {

        // Fetch visitors count and dates
        axios.get("/api/visitors", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            // Assurez-vous que l'API retourne bien la liste des visiteurs
            const visitors = response.data;
            setVisitors(response.data);
            // Format et agrégation des données par date
            const formattedVisitData = visitors.reduce((acc, visit) => {
                const visitDate = new Date(visit.createdAt).toLocaleDateString(); // Formater la date
                acc[visitDate] = (acc[visitDate] || 0) + 1; // Incrémenter le nombre de visites par date
                return acc;
            }, {});
        
            // Extraire les dates et les comptes de visites
            setVisitDates(Object.keys(formattedVisitData)); // Définir les dates pour l'axe x
            setVisitData(Object.values(formattedVisitData)); // Définir les nombres de visites pour l'axe y
        }).catch((error) =>{
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Impossible de charger les informations des visiteurs' });
             console.error(error)});

        // Fetch users count
        axios.get("/api/users", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            setUsers(response.data);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Impossible de charger les informations des utilisateurs' });
            console.error(error)});

        // Fetch doctors count
        axios.get("/api/medecins", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            setDoctors(response.data);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Impossible de charger les informations des medecins' });
            console.error(error)});

        // Fetch feedbacks count
        axios.get("/api/feedbacks", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            setFeedbacks(response.data);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Impossible de charger les Feedbacks' });
            console.error(error)});

        
        // Fetch RDVs 
        axios.get("/rendezvous-stats", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            setRdvs(response.data);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Impossible de charger les RDVs' });
            console.error(error)});

    }, []);

    // Fonction pour supprimer un utilisateur
    const deleteUser = (id) => {
        axios.delete(`/api/users/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            } 
        }).then(() => {
            setUsers(users.filter(user => user.id !== id));
            setVisibleUsers(false);
            toast.current.show({ severity: 'success', summary: 'Ok ', detail: 'Opération Reussie !!' });
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite' });
            console.error(error)});
    };

    // Fonction pour supprimer un médecin
    const deleteDoctor = (id) => {
        axios.delete(`/api/medecins/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            } 
        }).then(() => {
            toast.current.show({ severity: 'success', summary: 'Ok ', detail: 'Opération Reussie !!' });
            setDoctors(doctors.filter(doctor => doctor.id !== id));
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite' });
            console.error(error)});
    };

    // Fonction pour supprimer un feedback
    const deleteFeedback = (id) => {
        axios.delete(`/api/feedbacks/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            } 
        }).then(() => {
            setVisibleFeedbacks(false)
            toast.current.show({ severity: 'success', summary: 'Ok ', detail: 'Opération Reussie !!' });
            setFeedbacks(feedbacks.filter(feedback => feedback.id !== id));
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite' });
            console.error(error)});
    };

    // Options du graphique ApexCharts
    const visitOptions = {
        chart: {
            type: 'line',
            height: 350
        },
        xaxis: {
            categories: visitDates, // Les dates des visites
            title: {
                text: 'Dates de visite'
            }
        },
        yaxis: {
            title: {
                text: 'Nombre de visites'
            }
        },
        title: {
            text: 'Visites dans le temps',
            align: 'center'
        },
        stroke: {
            curve: 'smooth'
        },
        markers: {
            size: 5
        }
    };

    const countOptions = {
        chart: {
            type: 'bar',
            height: 350
        },
        xaxis: {
            categories: ['Visiteurs', 'Utilisateurs', 'Médecins', 'Feedbacks']
        },
        title: {
            text: 'Proportions des utilisateurs',
            align: 'center'
        },
        plotOptions: {
            bar: {
                distributed: true,
                horizontal: false,
                columnWidth: '55%'
            }
        },
        dataLabels: {
            enabled: false
        }
    };

    const countSeries = [{
        data: [visitors?.length, users.length, doctors.length, feedbacks.length]
    }];

    return (
        <>
            <div className="app-wrapper">
                <HeaderAdmin />
                <Toast ref={toast} />
                <main className="app-main">
                    <div className="app-content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h3 className="mb-0">Dashboard</h3>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-end">
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Dashboard
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="app-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-3 col-6">
                                    <div className="small-box text-bg-primary">
                                        <div className="inner">
                                            <h3>{visitors?.length}</h3>
                                            <p>Visiteurs</p>
                                        </div>
                                        <Link to="#" className="small-box-footer link-light">
                                            Voir plus <i className="bi bi-link-45deg"></i>
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-6">
                                    <div className="small-box text-bg-success">
                                        <div className="inner">
                                            <h3>{users?.length}</h3>
                                            <p>Utilisateurs</p>
                                        </div>
                                        <Link to="#"  onClick={() => setVisibleUsers(true)}className="small-box-footer link-light">
                                            Voir plus<i className="bi bi-link-45deg"></i>
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-6">
                                    <div className="small-box text-bg-warning">
                                        <div className="inner">
                                            <h3>{doctors?.length}</h3>
                                            <p>Médecins</p>
                                        </div>
                                        <Link to="#" onClick={() => setVisibleDoctors(true)}  className="small-box-footer link-light">
                                            Voir plus<i className="bi bi-link-45deg"></i>
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-6">
                                    <div className="small-box text-bg-danger">
                                        <div className="inner">
                                            <h3>{feedbacks?.length}</h3>
                                            <p>Feedbacks</p>
                                        </div>
                                        <Link to="#" onClick={() => setVisibleFeedbacks(true)}  className="small-box-footer link-light">
                                            Voir plus<i className="bi bi-link-45deg"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <h2> Statistiques sur les rendez-vous: </h2>
                                <div className="col-lg-3 col-6">
                                    <div className="small-box text-bg-success">
                                        <div className="inner">
                                            <h3>{rdv?.totalRendezVous}</h3>
                                            <p>Tous</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-6">
                                    <div className="small-box  text-bg-warning">
                                        <div className="inner">
                                            <h3>{rdv?.acceptedRendezVous}</h3>
                                            <p>Accepter</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-6">
                                    <div className="small-box text-bg-danger ">
                                        <div className="inner">
                                            <h3>{rdv?.refusedRendezVous}</h3>
                                            <p>Refuses</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-6">
                                    <div className="small-box  text-bg-primary">
                                        <div className="inner">
                                            <h3>{rdv?.resolvedProblems}</h3>
                                            <p>Problèmes résolus</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <h4>Visites dans le temps</h4>
                                    <ApexCharts options={visitOptions} series={[{ name: 'Visites', data: visitData }]} type="line" height={350} />
                                </div>
                                <div className="col-12 mt-5">
                                    <h4>Proportions</h4>
                                    <ApexCharts options={countOptions} series={countSeries} type="bar" height={350} />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <AdminFooter />
                 {/* Modal Utilisateurs */}
            <Dialog header="Liste des utilisateurs" visible={visibleUsers} style={{ width: '50vw' }} onHide={() => setVisibleUsers(false)}>
                <DataTable value={users}>
                    <Column body={(rowData, index) => index.rowIndex + 1} header="ID" />
                    {/* <Column field/="id" header="ID" /> */}
                    <Column field="nom" header="Nom" />
                    <Column field="prenom" header="Prénom" />
                    <Column field="email" header="Email" />
                    <Column body={(rowData) => <Button  severity="secondary" title="Supprimer"  style={{borderRadius:"12px"}} icon={<i className="pi pi-trash" style={{ fontSize: '24px', color: 'red' }}></i>} onClick={() => deleteUser(rowData.id)} />} />
                </DataTable>
            </Dialog>

            {/* Modal Médecins */}
            <Dialog header="Liste des médecins" visible={visibleDoctors} style={{ width: '50vw' }} onHide={() => setVisibleDoctors(false)}>
                <DataTable value={doctors}>
                    <Column body={(rowData, index) => index.rowIndex + 1} header="ID" />
                    {/* <Column field="id/" header="ID" /> */}
                    <Column field="nom" header="Nom" />
                    <Column field="prenom" header="Prénom" />
                    <Column field="specialite" header="Spécialité" />
                    <Column body={(rowData) => <Button  severity="secondary" title="Supprimer"  style={{borderRadius:"12px"}} icon={<i className="pi pi-trash" style={{ fontSize: '24px', color: 'red' }}></i>} onClick={() => deleteDoctor(rowData.id)} />} />
                </DataTable>
            </Dialog>

            {/* Modal Feedbacks */}
            <Dialog header="Liste des feedbacks" visible={visibleFeedbacks} style={{ width: '50vw' }} onHide={() => setVisibleFeedbacks(false)}>
                <DataTable value={feedbacks}>
                    <Column body={(rowData, index) => index.rowIndex + 1} header="ID" />
                    <Column field="commentaire" header="Message" />
                    <Column body={(rowData)=>(<Rating value={rowData.note} readOnly cancel={false}/>)} header="Note" />
                    <Column body={(rowData) => <Button severity="secondary" title="Supprimer"  style={{borderRadius:"12px"}} icon={<i className="pi pi-trash" style={{ fontSize: '24px', color: 'red' }}></i>}  onClick={() => deleteFeedback(rowData.id)} />} />
                </DataTable>
            </Dialog>   
            </div>

           
        </>
    );
}
