import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Menu } from 'primereact/menu';
import HeaderAdmin from "../partials/header-admin";
import AdminFooter from "../partials/admin-footer";
import axios from "../utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";

export default function InfoListPage() {
    const [infosSanitaires, setInfosSanitaires] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const menu = useRef(null);
    const navigate = useNavigate();
    const [selectedInfo, setSelectedInfo] = useState(null);  // Stocker l'info sélectionnée

    const token = localStorage.getItem('token'); 

    useEffect(() => {
        const fetchInfosSanitaires = async () => {
            try {
                const response = await axios.get('/informations_sanitaires', {
                    headers: {
                        'Authorization': token ? `Bearer ${token}` : ''
                    }
                });
                setInfosSanitaires(response.data);
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Impossible de charger les informations sanitaires' });
            }
        };
        fetchInfosSanitaires();
    }, [token]);

    const handleDelete = async () => {
        if (!selectedInfo) return;

        try {
            await axios.delete(`/informations_sanitaires/${selectedInfo.id}`, {
                headers: {
                    'Authorization': token ? `Bearer ${token}` : ''
                }
            });
            setInfosSanitaires(infosSanitaires.filter(info => info.id !== selectedInfo.id));
            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Information sanitaire supprimée' });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de la suppression de l\'information sanitaire' });
        }
    };

    const editInfo = () => {
        if (!selectedInfo) return;

        // Redirection vers la page d'édition avec l'ID de l'article sélectionné
        navigate(`/admin-infosanitaire-editer/${selectedInfo.id}`);
    };

    const viewDetails = () => {
        if (!selectedInfo) return;

        // Redirection vers la page de détails avec l'ID de l'article sélectionné
        navigate(`/healthDetail/${selectedInfo.id}`);
    };

    const showOptions = (event, info) => {
        setSelectedInfo(info);  // Stocker l'info sanitaire sélectionnée
        menu.current.toggle(event);
    };

    const renderActions = (rowData) => {
        return (
            <>
                <Menu model={[
                    { label: 'Détails', icon: 'pi pi-search', command: viewDetails },
                    { label: 'Modifier', icon: 'pi pi-pencil', command: editInfo },
                    { label: 'Supprimer', icon: 'pi pi-trash', command: handleDelete }
                ]} popup ref={menu} />
                <Button icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text" onClick={(e) => showOptions(e, rowData)} />
            </>
        );
    };

    return (
        <>
            <div className="app-wrapper">
                <HeaderAdmin />
                <main className="app-main">
                    <div className="app-content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h3 className="mb-0">Liste des Informations Sanitaires</h3>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-end">
                                        <li className="breadcrumb-item"><a href="/admin">/admin</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">info_sanitaires</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="app-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="p-inputgroup mb-3">
                                        <InputText
                                            type="search"
                                            placeholder="Rechercher..."
                                            onInput={(e) => setGlobalFilter(e.target.value)}
                                        />
                                        <Button icon="pi pi-search" />
                                    </div>
                                    
                                    <DataTable 
                                        value={infosSanitaires} 
                                        paginator 
                                        rows={15} 
                                        rowsPerPageOptions={[5, 10, 20]} 
                                        globalFilter={globalFilter} 
                                        header={`Informations Sanitaires :(${infosSanitaires.length})`}
                                        responsiveLayout="scroll"
                                        rowClassName={(data, index) => index % 2 === 0 ? 'even-row' : 'odd-row'} // Lignes alternées
                                        sortField="titre" // Tri
                                        sortOrder={1} // Ordre ascendant par défaut
                                    >   
                                        <Column 
                                            header="Num" 
                                            body={(rowData, {rowIndex}) => rowIndex + 1} // Affichage du numéro de ligne
                                        />
                                        <Column field="titre" header="Titre" sortable />
                                        <Column field="resume" header="Résumé" sortable />
                                        <Column field="Category.nom" header="Catégorie" sortable />
                                        <Column 
                                            field="source" 
                                            header="Source" 
                                            sortable 
                                            body={(rowData) => rowData.source || "aucun"} 
                                        />
                                        <Column 
                                            field="nomFormationSanitaire" 
                                            header="Formation Sanitaire" 
                                            sortable 
                                            body={(rowData) => rowData.nomFormationSanitaire || "aucun"} 
                                        />
                                        <Column 
                                            field="createdAt" 
                                            header="Date de Création" 
                                            sortable 
                                            body={(rowData) => new Date(rowData.createdAt).toLocaleDateString()} 
                                        />
                                        <Column header="Actions" body={renderActions} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <AdminFooter />
            </div>

            <Toast ref={toast} />
        </>
    );
}
