import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';
import axios from './../utils/axiosConfig';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';


const sexes = [
  { label: 'Homme', value: 'Homme' },
  { label: 'Femme', value: 'Femme' },
];

const Register = () => {
  const role  = 'Patient';
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [userInf, setUserInf] = useState({
    pseudo: '',
    nom: '',
    prenom: '',
    email: '',
    sexe: '',
    mot_de_passe: '',
    role: 'Patient',
    numero_de_telephone: '',
    partnerId: '',
    service: '',
    date_de_naissance: null,
    tranchesHoraires: {},
  });
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [partenaires, setPartenaires] = useState([]);


  useEffect(() => {

   const  fetchPartners = async()=>{
      try {
        const response = await axios.get('/partenaires');
        // setPartenaires(response.data);

        // formater les partenaires sous le format:  sigle (nom)
        const formatedPartners  = [];
          response.data.forEach(partner => {
           formatedPartners.push({...partner, label:`${partner.sigle} - ${partner.nom}`})
          
        });
        setPartenaires(formatedPartners);
      } catch (error) {
        console.error('Erreur lors de la récupération des partenaires:', error);
      }
    }

    fetchPartners();
  }, []);

  const handleChange = (event) => {
    setUserInf({ ...userInf, [event.target.name]: event.target.value });
  };





  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let requiredFields = [];
    if (role === 'Patient') {
      requiredFields = ['nom', 'mot_de_passe', 'sexe', 'date_de_naissance'];
    } else if (role === 'Medecin') {
      requiredFields = ['nom', 'prenom', 'email', 'mot_de_passe', 'sexe', 'numero_de_telephone', 'date_de_naissance'];
    }

    for (let field of requiredFields) {
      if (!userInf[field]) {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: `Le champ ${field} est requis.`, life: 3000 });
        return;
      }
    }

    if (!validatePassword(userInf.mot_de_passe)) {
      toast.current.show({
        severity: 'error',
        summary: 'Erreur',
        detail: "Le mot de passe doit contenir au moins 5 caractères, une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.",
        life: 3000
      });
      return;
    }

    if (!checked) {
      toast.current.show({
        severity: 'error',
        summary: 'Erreur',
        detail: "Vous devez accepter les conditions générales d'utilisation.",
        life: 3000
      });
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post("/register", {
        ...userInf,
        tranchesHoraires: userInf.tranchesHoraires,
      });
      console.log(response.data);
      localStorage.setItem("user_informations", JSON.stringify(response.data.utilisateur));
      localStorage.setItem("token", response.data.token);
      toast.current.show({
        severity: 'success',
        summary: 'Succès',
        detail: 'Enregistrement effectué avec succès !',
        life: 3000
      });
      setTimeout(() => navigate("/rdv"), 2000);
    } catch (error) {
      console.error("Erreur lors de l'enregistrement", error);
      toast.current.show({
        severity: 'error',
        summary: 'Erreur',
        detail: "Il y a eu une erreur lors de l'enregistrement.",
        life: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <NavBar/>
      <div id="main" style={{ height: '100%' }}>
      
        <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5 apropos" style={{ maxWidth: '480px', minHeight: '100vh', marginTop:'8rem' }}>
          <div className="container">
            <Card className="bg-light text-dark" style={{ borderRadius: '0' }}>
              <div className="card-body p-4 p-md-5 text-center">
                <div className="mb-4 mt-4 mb-md-5 mt-md-5 pb-3 pb-md-5">
                  <h1 className="h2 fw-bold mb-2 text-uppercase" style={{fontWeight:'bold', color:'#ffa500'}}>Créer un compte</h1>
                  <p className="text-dark-50 mb-5">Merci de renseigner les champs suivants</p>

                  {role === 'Patient' && (
                    <>
                      <div className="mb-4">
                        <InputText id="register-nom" name="nom" value={userInf.nom} onChange={handleChange} className="p-inputtext-lg"  placeholder="Entrez votre pseudo *" />
                      </div>
                      <div className="mb-4">
                        <InputText id="register-email" name="email" value={userInf.email} onChange={handleChange} className="p-inputtext-lg" placeholder="Entrez votre email *" />
                      </div>

                      <div className="mb-4">
                        <InputText id="register-telephone" name="numero_de_telephone" value={userInf.numero_de_telephone} onChange={handleChange} className="p-inputtext-lg" placeholder="Numéro de téléphone *" />
                      </div>
                    </>
                  )}


                  {/* Sexe en tant que Dropdown */}
                  <div className="mb-4" style={{width: '96%'}}>
                    <Dropdown id="register-sexe" name="sexe" value={userInf.sexe} options={sexes} onChange={(e) => setUserInf({ ...userInf, sexe: e.value })} placeholder="Sélectionnez votre sexe *" className="w-75" />
                  </div>

                  {/* Date de naissance */}
                  <div className="mb-4">
                    <Calendar id="date-naissance" name="date_de_naissance" value={userInf.date_de_naissance} onChange={(e) => setUserInf({ ...userInf, date_de_naissance: e.value })} className="p-inputtext-lg" placeholder="Date de Naissance *" />
                  </div>


                  {/* Sexe en tant que Dropdown */}
                  <div className="mb-4" style={{width: '96%'}}>
                    <h5> Votre association d'appartenance </h5>
                    <Dropdown id="register-partner" name="parter" value={userInf.partnerId} optionValue='id' optionLabel='label' options={partenaires} onChange={(e) => setUserInf({ ...userInf, partnerId: e.value })} placeholder="Selectionner votre association" className="w-75" />
                  </div>

                  <div className="mb-4">
                    <Password id="register-password" name="mot_de_passe" value={userInf.mot_de_passe} onChange={handleChange} className="p-inputtext-lg" placeholder="Entrez votre mot de passe *" toggleMask />
                  </div>

                 
                      <div className="form-check text-start mb-4 d-flex justify-content-between align-content-center align-items-center w-100 ">
                      <Checkbox onChange={e => setChecked(e.checked)} checked={checked} required />
                      <label htmlFor="register-cgv" style={{ marginLeft: "1rem" }}>Acceptez-vous nos <span className="text-warning m-1">conditions générales d'utilisation</span>.</label> 
                    </div>

               



                    {loading && <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration="1s" />}

                    {/* Bouton d'enregistrement */}
                    <Button label="Enregistrer" icon="pi pi-check" className="p-button-secondary p-button-lg px-5 btn-form-submit-register" onClick={handleSubmit} />

              
                </div>
                <p className="mb-0">Vous avez déjà un compte ? <Link to="/login">Connectez-vous</Link></p>
              </div>
            </Card>
          </div>
        </section>
        <Footer />
      </div>
     
    </div>
  );
};

export default Register;
