import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosConfig'; 
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from 'primereact/editor'; // Éditeur de texte
import { Dialog } from 'primereact/dialog'; // Modal de remerciement
import NavBar from '../partials/nav';
import Footer from '../partials/footer';

const FeedbackForm = () => {
    const { id } = useParams(); // Récupère l'ID du rendez-vous depuis l'URL
    const [rendezvous, setRendezvous] = useState(null);
    const [problemeTraite, setProblemeTraite] = useState(null); // Oui/Non pour le problème traité
    const [experience, setExperience] = useState(''); // Contenu de l'expérience
    const toast = useRef(null);
    const [showThankYouDialog, setShowThankYouDialog] = useState(false); // Pour le modal de remerciement
    const navigate = useNavigate();
    // Fonction pour récupérer le rendez-vous par son ID
    const fetchRendezVous = async () => {
        try {
            const response = await axiosInstance.get(`/rendezvous/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            setRendezvous(response.data);
        } catch (error) {
            console.error("Erreur lors de la récupération du rendez-vous:", error);
        }
    };

    // Fonction pour soumettre le feedback
    const submitFeedback = async () => {
        if (!problemeTraite) {
            toast.current.show({ severity: 'warn', summary: 'Erreur', detail: 'Veuillez indiquer si le problème a été traité.', life: 3000 });
            return;
        }
        
        if (experience.length > 1000) {
            toast.current.show({ severity: 'warn', summary: 'Erreur', detail: 'Votre expérience ne doit pas dépasser 1000 mots.', life: 3000 });
            return;
        }

        try {
            const feedbackData = {
                problemeTraite,
                experience,
                rendezvousId: id,
            };

            // Envoie les données de feedback à l'API
            await axiosInstance.post('/rdv-feedback', feedbackData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            setShowThankYouDialog(true); // Affiche le modal de remerciement
        } catch (error) {
            console.error("Erreur lors de l'envoi du feedback:", error);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de l\'envoi du feedback.', life: 3000 });
        }
    };

    useEffect(() => {
        fetchRendezVous(); // Récupère les informations du rendez-vous dès que la page se charge
    }, []);

    // Contenu du modal de remerciement
    const thankYouDialogFooter = (
        <Button label="Fermer" icon="pi pi-check" onClick={() => setShowThankYouDialog(false)} />
    );

    return (
        <div>
          <div id="main" style={{ height: '100%' }}>
            <NavBar/>
            <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5 apropos" style={{ marginTop:"8rem", minHeight: '100vh' }}>
                <div className="container mt-4">
                    <Toast ref={toast} />
                    {rendezvous ? (
                        <div>
                            <h2>Feedback pour le rendez-vous avec le prestataire {rendezvous.Medecin.Utilisateur.nom}</h2>
                            <p>Patient : {rendezvous.Patient.Utilisateur.nom}</p>
                            <p>Date du rendez-vous : {new Date(rendezvous.date_heure).toLocaleDateString()}</p>

                            <div className="field-radiobutton mt-4">
                                <h3>Votre problème a-t-il été traité ?</h3>
                                <div className="flex align-items-center">
                                    <RadioButton 
                                        inputId="oui" 
                                        name="problemeTraite" 
                                        value="oui" 
                                        onChange={(e) => setProblemeTraite(e.value)} 
                                        checked={problemeTraite === 'oui'}
                                    />
                                    <label htmlFor="oui" className="ml-2">Oui</label>
                                </div>
                                <div className="flex align-items-center">
                                    <RadioButton 
                                        inputId="non" 
                                        name="problemeTraite" 
                                        value="non" 
                                        onChange={(e) => setProblemeTraite(e.value)} 
                                        checked={problemeTraite === 'non'}
                                    />
                                    <label htmlFor="non" className="ml-2">Non</label>
                                </div>
                            </div>

                            <div className="mt-4">
                                <h3>Racontez votre expérience</h3>
                                <Editor 
                                    value={experience} 
                                    onTextChange={(e) => setExperience(e.htmlValue)} 
                                    style={{ height: '200px' }} 
                                    placeholder="Décrivez votre expérience (max. 1000 mots)" 
                                />
                                <p>Limite : {experience?.split(/\s+/).filter(word => word.length > 0).length} mots</p>
                            </div>

                            <p className="mt-4">
                                <strong>Note :</strong> Vos réponses peuvent être publiées anonymement.
                            </p>

                            <Button label="Envoyer le Feedback" className="p-button-success mt-3" onClick={submitFeedback} />

                            <Dialog header="Merci pour votre feedback !" visible={showThankYouDialog} style={{ width: '50vw' }} footer={thankYouDialogFooter} onHide={() => {setShowThankYouDialog(false); navigate("/")}}>
                                <p>Nous apprécions votre retour d'expérience. Vos réponses peuvent être publiées de manière anonyme. Merci pour votre collaboration.</p>
                            </Dialog>
                        </div>
                    ) : (
                        <p>Chargement des informations du rendez-vous...</p>
                    )}
                </div>
            </section>
        <Footer />
        </div>
    </div>
    );
};

export default FeedbackForm;
