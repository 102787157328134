import React from 'react';
import { Carousel } from 'primereact/carousel';
import './ImageCarousel.css'; // Importez le fichier CSS pour les styles

const ImageCarousel = () => {
  // Images et textes associés
  const slides = [
    {
      image: '/data/applications/pictures/atelier.jpeg',
      text: 'Bienvenue chez YohedaCare  Votre bien-être, notre priorité.',
    },
    {
      image: '/data/applications/pictures/atelier1.jpeg',
      text: 'Un accompagnement sur personnalisé et mesure.',
    },
    {
      image: '/data/applications/pictures/douala1.jpeg',
      text: 'La santé plus proche des personnes vulnerables.',
    },
    {
      image: '/data/applications/pictures/douala3.jpeg',
      text: 'La santé plus proche des personnes vulnerables.',
    },
    {
      image: '/data/applications/pictures/douala4.jpeg',
      text: 'La santé plus proche des personnes vulnerables.',
    },
    {
      image: '/data/applications/pictures/edition1-2.jpeg',
      text: 'La santé plus proche des personnes vulnerables.',
    },
    {
      image: '/data/applications/pictures/edition1-3.jpeg',
      text: 'La santé plus proche des personnes vulnerables.',
    },
    {
      image: '/data/applications/pictures/edition1-4.jpeg',
      text: 'La santé plus proche des personnes vulnerables.',
    },
    {
      image: '/data/applications/pictures/edition1-6.jpeg',
      text: 'La santé plus proche des personnes vulnerables.',
    },
    {
      image: '/data/applications/pictures/edition1-7.jpeg',
      text: 'La santé plus proche des personnes vulnerables.',
    },
    
    // Ajoutez d'autres images et textes si nécessaire
  ];

  // Template pour chaque slide (image + texte)
  const imageTemplate = (slide) => {
    // Détecte si c'est l'image atelier.jpeg
    const isAtelierImage = slide.image === '/data/applications/pictures/atelier.jpeg';

    return (
      <div className={`image-item ${isAtelierImage ? 'atelier-container' : ''}`}>
        <img
          src={slide.image}
          alt="YohedaCare"
          className="carousel-image"
        />
        <div className="carousel-text">
          {slide.text.split('/').map((word, index) => (
            <span key={index} style={{ animationDelay: `${index * 0.1}s` }}>
              {word}{' '}
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={`carousel-container`}>
      <Carousel 
        value={slides} 
        itemTemplate={imageTemplate} 
        numVisible={1} 
        numScroll={1}
        autoplayInterval={5000} // Défiler automatiquement toutes les 5 secondes
        className="custom-carousel"
        circular
      />
    </div>
  );
};

export default ImageCarousel;
