import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Avatar } from 'primereact/avatar';
import { Calendar } from 'primereact/calendar';
import './Appointments.css';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';
import { Paginator } from 'primereact/paginator';
import axios, { baseURL } from './../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';

const Appointments = () => {
    const [appointments, setAppointments] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showDateDialog, setShowDateDialog] = useState(false);
    const [newDateTime, setNewDateTime] = useState(null);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false); // For "Decline" modal
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [appointmentsPerPage] = useState(6);
    const [expandedAppointmentId, setExpandedAppointmentId] = useState(null);
    const navigate = useNavigate();
    const healthcarePersonnel = 'Dr. Smith'; // Placeholder for the healthcare personnel's name

    useEffect(() => {
        fetchAppointments();
    }, []);

    const fetchAppointments = async () => {
        try {
            const response = await axios.get('/rendezvous_invalide/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setAppointments(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des rendez-vous:', error);
        }
    };

    const validateAppointment = async (type) => {
        try {
            await axios.put(`/rendezvous/${selectedAppointment.id}/valider`, { type }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setAppointments(appointments.filter(a => a.id !== selectedAppointment.id));
            setShowDialog(false);
            navigate('/acceptedAppointments')
        } catch (error) {
            console.error('Erreur lors de la validation du rendez-vous:', error);
        }
    };

    const rescheduleAppointment = async () => {
        try {
            if (!newDateTime) {
                alert('Veuillez sélectionner une nouvelle date et heure.');
                return;
            }

            await axios.put(`/rendezvous/${selectedAppointment.id}/reporter`, { newDateTime }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            const politeMessage = `Cher patient, votre rendez-vous a été reporté avec succès au ${newDateTime.toLocaleDateString('fr-FR')} à ${newDateTime.toLocaleTimeString('fr-FR')}. Cordialement, ${healthcarePersonnel}.`;

            alert(politeMessage); // Display success message
            setAppointments(appointments.filter(a => a.id !== selectedAppointment.id)); // Remove from list
            setShowDateDialog(false); // Close the dialog
        } catch (error) {
            console.error('Erreur lors du report du rendez-vous:', error);
        }
    };

    const rejectAppointment = async () => {
        try {
            await axios.delete(`/rendezvous/rejeter/${selectedAppointment.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            const politeMessage = `Operation reussie !`;

            alert(politeMessage); // Display success message
            setAppointments(appointments.filter(a => a.id !== selectedAppointment.id)); // Remove from list
            setShowConfirmationDialog(false); // Close the dialog
        } catch (error) {
            console.error('Erreur lors du rejet du rendez-vous:', error);
        }
    };

    const toggleDescription = (id) => {
        setExpandedAppointmentId(expandedAppointmentId === id ? null : id);
    };

    const indexOfLastAppointment = (currentPage + 1) * appointmentsPerPage;
    const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
    const currentAppointments = appointments.slice(indexOfFirstAppointment, indexOfLastAppointment);

    const onPageChange = (event) => {
        setCurrentPage(event.first / appointmentsPerPage);
    };

    const openRescheduleDialog = () => {
        setShowDateDialog(true); // Open the reschedule dialog with calendar
        setShowDialog(false); // Close main actions dialog
    };

    const openDeclineConfirmation = () => {
        setConfirmationMessage('Êtes-vous sûr de vouloir décliner le rendez-vous ?');
        setShowConfirmationDialog(true); // Open decline confirmation modal
        setShowDialog(false); // Close main actions dialog
    };

    const renderAppointmentCard = (appointment) => {
        const isExpanded = expandedAppointmentId === appointment.id;

        const patientName = appointment.Patient ? `${appointment.Patient.Utilisateur.nom} ${appointment.Patient.Utilisateur.prenom}` : 'Nom non disponible';
        const patientphoto = appointment.Patient ? `${baseURL + appointment.Patient.Utilisateur.photo}` : "https://randomuser.me/api/portraits/men/1.jpg";
        const status = appointment.etat ? 'Validé' : 'En attente';

        return (
            <Card key={appointment.id} className="appointment-card" >
                <div className="card-content">
                    <div className="appointment-header d-flex align-items-center">
                        <Avatar image={patientphoto} shape="circle" size="large" className="avatar" />
                        <div>
                            <p className="patient-name">Nom : {patientName}</p>
                            <p className="date-time patient-name">Date : {new Date(appointment.date_heure).toLocaleDateString('fr-FR')}</p>
                            <p className="date-time patient-name">Durée : {new Date(appointment.date_heure).toLocaleTimeString('fr-FR')} - {new Date(appointment.date_heure_fin).toLocaleTimeString('fr-FR')}</p>
                            <p className="status">Statut : {status}</p>
                        </div>
                    </div>
                    <div className={`description ${isExpanded ? 'expanded' : 'collapsed'}`}>
                        Description : {appointment.notes}
                    </div>
                    <Button
                        icon={isExpanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
                        className="p-button-text toggle-button"
                        onClick={() => toggleDescription(appointment.id)}
                        tooltip={isExpanded ? "Réduire" : "Afficher plus"}
                        tooltipOptions={{ position: 'top' }}
                    />
                    <div className="card-actions">
                        <Button label="Actions" icon="pi pi-info" className="p-button-success" onClick={() => { setSelectedAppointment(appointment); setShowDialog(true); }} />
                    </div>
                </div>
            </Card>
        );
    };

    return (
        <>
            <NavBar />
            <div className="appointments-container" style={{ backgroundColor: '#deff' }}>
                <h3 className='apropos' style={{ fontWeight:'bold', color:'#ffa500', marginLeft: '6.7rem', marginBottom: '3rem', marginTop: '9rem' }}>Liste des Rendez-vous</h3>
                <div className="appointments-grid mb-5"style={{}}>
                    {currentAppointments.length > 0 ? currentAppointments.map(renderAppointmentCard) :
                        <div><Card className='mt-4 mb-4 ' title="Oups !"><p>Pas de RDV à valider pour l'instant</p></Card></div>}
                </div>

                <Paginator
                    first={currentPage * appointmentsPerPage}
                    rows={appointmentsPerPage}
                    totalRecords={appointments.length}
                    onPageChange={onPageChange}
                    template="PrevPageLink PageLinks NextPageLink"
                    className="custom-paginator"
                />

                <Dialog header="Confirmer l'action" visible={showDialog} style={{ width: '90vw', maxWidth: '600px' }} modal footer={
                    <div className='d-flex justify-content-between'>
                           <Button label="Accepter" icon="pi pi-check" className="p-button-success" onClick={() => validateAppointment('ligne')} />
                        <Button label="Reporter" icon="pi pi-calendar" className="p-button-warning" onClick={openRescheduleDialog} />
                        <Button label="Décliner" icon="pi pi-times" className="p-button-danger" onClick={openDeclineConfirmation} />
                    </div>
                } onHide={() => setShowDialog(false)}>
                    <p>Voulez-vous reporter ou décliner le rendez-vous de {selectedAppointment ? `${selectedAppointment.Patient.Utilisateur.nom} ${selectedAppointment.Patient.Utilisateur.prenom}` : 'Nom non disponible'} ?</p>
                </Dialog>

                {/* Modal for Rescheduling */}
                <Dialog header="Choisir une nouvelle date et heure" visible={showDateDialog} style={{ width: '90vw', maxWidth: '600px' }} modal footer={
                    <div className='d-flex justify-content-between'>
                        <Button label="Confirmer" icon="pi pi-check" className="p-button-success" onClick={rescheduleAppointment} />
                        <Button label="Annuler" icon="pi pi-times" className="p-button-secondary" onClick={() => setShowDateDialog(false)} />
                    </div>
                } onHide={() => setShowDateDialog(false)}>
                    <Calendar style={{alignContent:"center"}} value={newDateTime} onChange={(e) => setNewDateTime(e.value)} showIcon   icon={() => <i className="pi pi-calendar btn"></i> } dateFormat="dd/mm/yy" showTime />
                </Dialog>

                {/* Modal for Declining */}
                <Dialog header="Confirmation" visible={showConfirmationDialog} style={{ width: '90vw', maxWidth: '600px' }} modal footer={
                    <div className='d-flex justify-content-between'>
                        <Button label="Confirmer" icon="pi pi-check" className="p-button-success" onClick={rejectAppointment} />
                        <Button label="Annuler" icon="pi pi-times" className="p-button-secondary" onClick={() => setShowConfirmationDialog(false)} />
                    </div>
                } onHide={() => setShowConfirmationDialog(false)}>
                    <p>{confirmationMessage}</p>
                </Dialog>

            </div>
            <Footer />
        </>
    );
};

export default Appointments;
