import React, { useState, useEffect } from 'react';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';
import { Link, useParams } from 'react-router-dom';
import axios, { baseURL } from './../utils/axiosConfig';  

const PartenairesVilleInfo = () => {
    const { ville } = useParams();  // Récupérer la ville depuis les paramètres d'URL
    const [partenaire, setPartenaire] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Récupérer les données des partenaires pour la ville spécifique
    useEffect(() => {
        const fetchPartenaires = async () => {
            try {
                const response = await axios.get(`/ville/${ville}`);
                if (response.data && response.data.length > 0) {
                    setPartenaire(response.data[0]);  // Prendre le premier partenaire correspondant
                } else {
                    setError('Aucun partenaire trouvé pour cette ville.');
                }
                setLoading(false);
            } catch (err) {
                setError('Une erreur s\'est produite lors du chargement des données.');
                setLoading(false);
            }
        };

        fetchPartenaires();
    }, [ville]);

    if (loading) {
        return <div>Chargement de l'association...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!partenaire) {
        return <div>Aucun partenaire trouvé pour la ville : {ville}</div>;
    }

    return (
        <>
            <NavBar />
            <div ville="main apropos" style={{ height: 'auto' ,marginTop:'5rem'}}>
                <div className="container mb-3 mt-3 pt-2">
                    <div className="d-flex align-items-center mt-4 mb-5">
                        <Link to="/assistance" className="pe-3 d-inline-block">
                            <i className="fas fa-arrow-left fs-6" style={{fontWeight:'bold', color:'#ffa500'}}></i>
                        </Link>
                        <h1 className="fw-bolder h2 mb-0" style={{fontWeight:'bold', color:'#ffa500'}}>{partenaire.nom}</h1>
                    </div>
                    <h2 className="fs-7 mb-2 fw-lighter mb-2 mb-lg-4" style={{"color":"#249646", "fontWeight":"bold"}}>
                        Association de {ville}
                    </h2>
                    

                    <div className="bg-image mb-4">
                        <img
                             src={`${baseURL}/${partenaire.photo}`} alt={partenaire.nom} 
                            title={partenaire.nom}
                            className="w-100 rounded img-fluid"
                        />
                    </div>

                    <h2 className="fs-7 mb-2 fw-lighter mb-2 mb-lg-4" style={{"color":"#249646", "fontWeight":"bold"}}>
                        Leader: {partenaire.representant}
                    </h2>
                    <h2 className="fs-7 mb-2 fw-lighter mb-2 mb-lg-4" style={{"color":"#249646", "fontWeight":"bold"}}>
                        Numéro: {partenaire.numero}
                    </h2>
                    

                    <div className="py-2 py-lg-4">
                        <div dangerouslySetInnerHTML={{ __html: partenaire.description }}></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PartenairesVilleInfo;
