
import React from 'react';
import NavBar from '../partials/nav';
import Footer from '../partials/footer';


export default function Page404  (){  
    return(
        <>
            <NavBar />
            <div id="main" style={{"height": "849px;"}}>
                <div class="vh-100 container mt-5 pt-5">
                    <div class="row">
                        <div class="col-md-6 order-2 order-md-1">
                            <div class="text-center">
                                <img src="https://11090.app.creer-application.com/assets/img/not-found.png" alt="La page recherchée n'existe pas" class="img-fluid"/>
                            </div>
                        </div>
                        <div class="col-md-6 order-1 mb-5 order-md-2">
                            <h1 class="fw-bold fs-1 mb-3">Ooupss...</h1>
                            <p class="fs-3">La page recherchée n'existe pas...</p>
                            <p class="display-1 fw-bold">404</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
 };