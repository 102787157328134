import React, { useState, useEffect, useRef } from 'react';
import axiosInstance, { baseURL } from '../utils/axiosConfig';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { useNavigate } from 'react-router-dom';
import HeaderAdmin from '../partials/header-admin';
import AdminFooter from '../partials/admin-footer';
import { Avatar } from 'primereact/avatar';

const PartnersPage = () => {
    const [partners, setPartners] = useState([]);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const navigate = useNavigate(); // Utilisez useNavigate pour la redirection

    useEffect(() => {
        const fetchPartners = async () => {
            try {
                const token = localStorage.getItem("token")
                const response = await axiosInstance.get('/partenaires/',
                {
                    headers:{
                        Authorization: `Bearer ${token}`
                    }
                }); // Changer l'endpoint pour récupérer les partenaires
                setPartners(response.data);
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec du chargement des associations', life: 3000 });
            } finally {
                setLoading(false);
            }
        };

        fetchPartners();
    }, []);

    const handleActionClick = async (action, partner) => {
        if (action === 'edit') {
            console.log('Edit', partner);
            navigate(`/admin-partnerUpdate/${partner.id}/`); // Redirige vers la page de mise à jour du partenaire
        } else if (action === 'delete') {
            confirmDialog({
                message: 'Êtes-vous sûr de vouloir supprimer cette association?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    try {
                        const token = localStorage.getItem("token")
                        await axiosInstance.delete(`/partenaire/${partner.id}/`,
                        {
                            headers:{
                                Authorization: `Bearer ${token}`
                            }
                        }); // Changer l'endpoint pour supprimer le partenaire
                        setPartners(partners.filter(p => p.id !== partner.id));
                        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Association supprimé', life: 3000 });
                    } catch (error) {
                        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de la suppression de l\'association', life: 3000 });
                    }
                },
                reject: () => {
                    toast.current.show({ severity: 'info', summary: 'Annulé', detail: 'Suppression annulée', life: 3000 });
                }
            });
        }
    };

    const actionTemplate = (rowData) => (
        <Dropdown
            options={[
                { label: 'Modifier', value: 'edit' },
                { label: 'Supprimer', value: 'delete' }
            ]}
            onChange={(e) => handleActionClick(e.value, rowData)}
            placeholder="Actions"
            className="p-dropdown"
        />
    );

    return (
    <div className="app-wrapper">
        <HeaderAdmin />
        <main className="app-main">
            <div className="app-content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="mb-0">Liste des partenaires</h3>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-end">
                                <li className="breadcrumb-item"><a href="/admin">/admin</a></li>
                                <li className="breadcrumb-item active" aria-current="page">partenaires</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="app-content">
                <div className="container-fluid">
                    <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5" style={{ maxWidth: '1200px' }}>
                        <div className="container">
                            <div className="card bg-light text-dark" style={{ borderRadius: '8px' }}>
                                <div className="card-body p-4 p-md-5 text-center">
                                    <h1 className="h2 fw-bold mb-4">Listes Des Associations</h1>
                                    <DataTable value={partners} loading={loading} paginator rows={10} responsiveLayout="scroll">
                                        <Column field="image" body={(rowData=>(<div style={{alignContent:"center", marginLeft:"2rem"}}><Avatar image={`${baseURL}/${rowData.photo}`} size="large" shape="circle" /></div>))} header="Logo de l'association" />
                                        <Column field="nom" header="Nom" />
                                        <Column field="sigle" header="Sigle" />
                                        <Column field="ville" header="Ville" />
                                        <Column field="description" header="Description" />
                                        <Column field="representant" header="Représentant" />
                                        <Column field="numero" header="Numéro" />
                                        <Column header="Actions" body={actionTemplate} />
                                    </DataTable>
                                    <Toast ref={toast} />
                                    <ConfirmDialog />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
        <AdminFooter />
    </div>
    );
};

export default PartnersPage;
