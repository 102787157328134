import React, { useState, useEffect } from 'react';
import { Badge } from 'primereact/badge';
import axios from './axiosConfig';
import { Link } from 'react-router-dom';

const UnreadMessages = () => {
    const [unreadCount, setUnreadCount] = useState(0);


    useEffect(() => {
            // Fonction pour récupérer le nombre de messages non lus
    const fetchUnreadMessages = async () => {
        try {
            const response = await axios.get('/api/messages/unread', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setUnreadCount(response.data.unreadCount);
        } catch (error) {
            console.error('Erreur lors de la récupération des messages non lus', error);
        }
    };

        // Récupérer les messages non lus lors du montage
        fetchUnreadMessages();

        // Écouter les messages du service worker
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', (event) => {
                const { type, data } = event.data;
                if (type === 'ws_new_message') {
                    // Mettre à jour le nombre de messages non lus
                    setUnreadCount((prevCount) => prevCount + 1);
                } else if (type === 'ws_message_read') {
                    // Mettre à jour le nombre après lecture d'un message
                    setUnreadCount((prevCount) => Math.max(0, prevCount - 1));
                }
            });
        }
    }, []);

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <Link to="/discussion">
                <i className="pi pi-comment" style={{ fontSize: '1.5rem', color:"#fbc02d" }}></i>
                {unreadCount > 0 && (
                    <Badge value={unreadCount.toString()} severity="danger" style={{ width:"1rem" , position: 'absolute', top: "-10px", right: "-10px", padding:"2px" }} />
                )}
            </Link>
        </div>
    );
};

export default UnreadMessages;
