import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://yoheda-back.onrender.com', 
    // headers: {
    //     'Content-Type': 'application/json',
    // },
});
const frontendUrl = 'https://care.yohedahealth.org'
const baseURL = 'https://yoheda-back.onrender.com';
const wsBackendUrl = "wss://yoheda-back.onrender.com";

axiosInstance.interceptors.response.use(
    response => {
        // Si la réponse est valide, juste retourner la réponse
        return response;
    },
    error => {
        if (error.response && ( error.response.status === 403)) {
            // Rediriger vers la page de login
            if(window.location.pathname !== '/login')
           {
            window.location.href = `${frontendUrl}/login`; // ou utiliser useHistory si tu es dans un composant React
           } 
        }
        // Rejeter la promesse pour propager l'erreur
        return Promise.reject(error);
    }
);
export {baseURL, wsBackendUrl, frontendUrl};
export default axiosInstance;
