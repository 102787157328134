import React, { Component } from 'react';
import axios from './../utils/axiosConfig';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ReactApexChart from 'react-apexcharts';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './StatisticsComponent.css'; // Importer un fichier CSS pour les styles
import HeaderAdmin from '../partials/header-admin';
import AdminFooter from '../partials/admin-footer';

class StatisticsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statistics: [],
      selectedRows: [], // Pour gérer les lignes sélectionnées
      series: [],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        title: {
          text: 'Graphique des Statistiques de Réponses',
          style: {
            color: '#008FFB',
            fontWeight: 'bold',
            
          },
        },
        
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        yaxis: [
          {
            seriesName: 'Réponses',
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB',
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
            },
            title: {
              text: 'Nombre de Réponses',
              style: {
                color: '#008FFB',
              },
            },
            tooltip: {
              enabled: true,
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Questions',
          },
          
        },
        legend: {
          position: 'right',
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (val) => `${val} réponses`,
          },
        },
      },
      loading: true,
      error: null,
      searchQuery: '',
      rowsPerPage: 5,
    };
  }

  componentDidMount() {
    this.fetchStatistics();
  }

  fetchStatistics = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/regroupement-questions', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const statistics = response.data.statistics;
      this.setChartData(statistics);
      this.setState({ statistics });
    } catch (err) {
      this.setState({ error: "Une erreur s'est produite lors du chargement des statistiques." });
    } finally {
      this.setState({ loading: false });
    }
  };

  setChartData(statistics) {
    const responseTypes = [...new Set(statistics.flatMap(stat => Object.keys(stat.reponses)))];
    const seriesData = responseTypes.map(responseType => ({
      name: responseType,
      data: statistics.map(stat => stat.reponses[responseType] || 0),
    }));

    const categories = statistics.map(stat => stat.question);

    this.setState(prevState => ({
      series: seriesData,
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: categories,
        },
      },
    }));
  }

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  // Fonction d'exportation CSV
  exportCSV = () => {
    const csvData = Papa.unparse(this.state.selectedRows.length ? this.state.selectedRows : this.state.statistics);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'export.csv');
  };

  // Fonction d'exportation Excel
  exportXLS = () => {
    const worksheet = XLSX.utils.json_to_sheet(this.state.selectedRows.length ? this.state.selectedRows : this.state.statistics);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'export.xlsx');
  };

  // Fonction d'exportation PDF
  exportPDF = () => {
    const doc = new jsPDF();
    const columns = ["Question", "Réponses", "Nombre Total de Réponses"];
    const rows = this.state.selectedRows.length
      ? this.state.selectedRows.map(row => [row.question, Object.entries(row.reponses).map(([k, v]) => `${k}: ${v}`).join(', '), row.total])
      : this.state.statistics.map(row => [row.question, Object.entries(row.reponses).map(([k, v]) => `${k}: ${v}`).join(', '), row.total]);
    
    doc.autoTable(columns, rows);
    doc.save('export.pdf');
  };

  renderHeader() {
    return (
      <div className="table-header">
        <h4 className="m-0">Statistiques des Réponses</h4>
        <InputText
          type="text"
          value={this.state.searchQuery}
          onChange={this.handleSearchChange}
          placeholder="Rechercher une question..."
          style={{ width: '300px' }}
        />
        <Dropdown
          value={null}
          options={[
            { label: 'Exporter CSV', value: 'csv' },
            { label: 'Exporter PDF', value: 'pdf' },
            { label: 'Exporter XLS', value: 'xls' },
          ]}
          onChange={(e) => {
            if (e.value === 'csv') this.exportCSV();
            else if (e.value === 'pdf') this.exportPDF();
            else if (e.value === 'xls') this.exportXLS();
          }}
          placeholder={<i className="pi pi-ellipsis-v"></i>}
          style={{ marginLeft: '52.6rem' }}
        />
      </div>
    );
  }

  render() {
    const { loading, error, series, options, statistics, searchQuery, rowsPerPage } = this.state;

    const filteredStatistics = statistics.filter(stat =>
      stat.question.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <div className="app-wrapper" style={{height:'100%'}}>
      <HeaderAdmin />
      <div className="container mt-5" style={{width:'100%'}}>
        <h2 className="text-center mb-4">Statistiques des Questions</h2>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '30rem' }}>
            <ProgressSpinner />
          </div>
        ) : error ? (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        ) : (
          <>
            <div className="card shadow-sm mb-5">
              <DataTable
                value={filteredStatistics}
                className="p-datatable-gridlines"
                dataKey="question"
                paginator
                rows={rowsPerPage}
                rowHover
                header={this.renderHeader()}
                footer={`Total des Réponses: ${this.state.totalResponses}`}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Montre {first} à {last} de {totalRecords} réponses"
                selection={this.state.selectedRows}
                onSelectionChange={(e) => this.setState({ selectedRows: e.value })}
              >
                <Column
                  selectionMode="multiple" headerStyle={{ width: '3em' }}
                />
                <Column
                  header="N°"
                  body={(rowData, options) => options.rowIndex + 1}
                  style={{ width: '50px', textAlign: 'center' }}
                />
                <Column field="question" header="Question" />
                <Column
                  header="Réponse"
                  body={rowData => (
                    <ul className="list-unstyled">
                      {Object.entries(rowData.reponses).map(([reponse, count]) => (
                        <li key={reponse}>{`${reponse}: ${count}`}</li>
                      ))}
                    </ul>
                  )}
                />
                <Column field="total" header="Nombre Total de Réponses" />
              </DataTable>
            </div>

            <div className="chart-container">
              <ReactApexChart options={options} series={series} type="bar" height={350} />
            </div>
          </>
        )}
      </div>
      <AdminFooter />
      </div>
    );
  }
}

export default StatisticsComponent;
