// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { InputText } from 'primereact/inputtext';
// import { FloatLabel } from 'primereact/floatlabel';
// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';
// import Footer from '../partials/footer';
// import NavBar from '../partials/nav';
// import axios from '../utils/axiosConfig';
// import ChocolateCardFactChecking from './ChocolateCardFactChecking';
// import ChocolateCardInfectious from './ChocolateCardInfectious';
// import ChocolateCardFosa from './ChocolateCardFosa';
// import ChocolateCardHealth from './ChocolateCardHealth';

// const HealthInfo = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [articles, setArticles] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

 

//   useEffect(() => {
//     const fetchArticles = async () => {
//       try {
//         const response = await axios.get('/informations_sanitaires');
//         setArticles(response.data);
//         setLoading(false);
//       } catch (err) {
//         setError('Une erreur s\'est produite lors du chargement des informations.');
//         setLoading(false);
//       }
//     };

//     fetchArticles();
//   }, []);

//   // Group articles by category
//   const groupArticlesByCategory = () => {
//     const grouped = {
//       articlesFactcheckings: { category: { id: 'articlesFactcheckings', nom: 'Articles Factchecking' }, articles: [] },
//       maladiesInfectieuses: { category: { id: 'maladiesInfectieuses', nom: 'Maladies Infectieuses' }, articles: [] },
//       fosa: { category: { id: 'fosa', nom: 'FOSA' }, articles: [] },
//       sante: { category: { id: 'sante', nom: 'Santé' }, articles: [] },
//     };

//     articles.forEach((article) => {
//       const categoryNom = article.Category.nom.toLowerCase();

//       if (categoryNom.includes('factchecking')) {
//         grouped.articlesFactcheckings.articles.push(article);
//       } else if (categoryNom.includes('infectieuse')) {
//         grouped.maladiesInfectieuses.articles.push(article);
//       } else if (categoryNom.includes('fosa')) {
//         grouped.fosa.articles.push(article);
//       } else if (categoryNom.includes('santé')) {
//         grouped.sante.articles.push(article);
//       }
//     });

//     return grouped;
//   };

//   const groupedArticles = groupArticlesByCategory();

//   const filterArticles = (articles) => {
//     if (!searchTerm) return articles;
//     return articles.filter((article) =>
//       article.titre.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       article.resume.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       article.Category.nom.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//   };

//   // Render articles using different components based on category
//   const renderArticleComponent = (article) => {
//     const categoryNom = article.Category.nom.toLowerCase();
    
//     if (categoryNom.includes('factchecking')) {
//       return <ChocolateCardFactChecking key={article.id} article={article} />;
//     } else if (categoryNom.includes('infectieuse')) {
//       return <ChocolateCardInfectious key={article.id} article={article} />;
//     } else if (categoryNom.includes('fosa')) {
//       return <ChocolateCardFosa key={article.id} article={article} />;
//     } else if (categoryNom.includes('santé')) {
//       return <ChocolateCardHealth key={article.id} article={article} />;
//     }
//     return null; // Fallback for any other cases
//   };

//   const renderArticlesByCategory = (category, articles) => {
//     const filteredArticles = filterArticles(articles);

//     return (
//       <div key={category.id} className="category-section" style={{ marginTop: '2rem' }}>
//         <h3 className="fs-4 fw-bold mb-3" style={{color: "orange"}}>{category.nom}</h3>
//         <div className="row g-md-5">
//           {filteredArticles.slice(0, 3).map((article) => (
//             <div key={article.id} className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-4">
//               {renderArticleComponent(article)}
//             </div>
//           ))}
//         </div>
//         {articles.length > 0 && (
//           <div className="text-end">
//             <Link to={`/categories/${articles[0].Category.id}/articles`} className="btn btn-link fs-6 fw-bold">
//               Voir plus <i className="pi pi-angle-right"></i>
//             </Link>
//           </div>
//         )}
//         <hr />
//       </div>
//     );
//   };

//   return (
//     <>
//      <NavBar />
//       <div id="main" style={{ height: '100%' }}>
      
//         <div className="bg-light text-dark px-2 py-3 p-lg-4 apropos" style={{marginTop: '8rem'}}>
//           <div className="container">
//             <h2 className="fs-4 fw-bolder mb-3" style={{fontWeight:'bold', color:'#ffa500'}}>Informations Sanitaires</h2>
//             <form className="row g-0 align-items-center" id="form-catalog-search-295742">
//               <div className="col-9 col-lg-10">
//                 <FloatLabel>
//                   <InputText
//                     id="catalog-search-query-295742"
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     className="form-control form-control-lg catalog-search-query"
//                     autoComplete="off"
//                     style={{ width: '100%' }}
//                   />
//                   <label htmlFor="catalog-search-query-295742">Rechercher</label>
//                 </FloatLabel>
//               </div>
//               <div className="col-3 col-lg-2">
//                 <button
//                   type="submit"
//                   className="btn btn-block btn-lg btn-secondary catalog-search-products"
//                   onClick={(e) => e.preventDefault()}
//                 >
//                   <i className="fas fa-search"></i>
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>

//         <div className="container pt-0 pb-5">
//           <div className="w-100">
//             {loading ? (
//               <p>Chargement des articles...</p>
//             ) : error ? (
//               <p>{error}</p>
//             ) : (
//               <>
//                 {renderArticlesByCategory(groupedArticles.sante.category, groupedArticles.sante.articles)}
//                 {renderArticlesByCategory(groupedArticles.articlesFactcheckings.category, groupedArticles.articlesFactcheckings.articles)}
//                 {renderArticlesByCategory(groupedArticles.maladiesInfectieuses.category, groupedArticles.maladiesInfectieuses.articles)}
//                 {renderArticlesByCategory(groupedArticles.fosa.category, groupedArticles.fosa.articles)}
               
//               </>
//             )}
//           </div>
//         </div>
//         <Footer />
//       </div>
      
//     </>
//   );
// };

// export default HealthInfo;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';
import axios from '../utils/axiosConfig';
import ChocolateCardFactChecking from './ChocolateCardFactChecking';
import ChocolateCardInfectious from './ChocolateCardInfectious';
import ChocolateCardMaternal from './ChocolateCardMaternal';
import ChocolateCardMental from './ChocolateCardMental';
import ChocolateCardReproductive from './ChocolateCardReproductive';
import ChocolateCardHealthLaw from './ChocolateCardHealthLaw';
import ChocolateCardHealthNews from './ChocolateCardHealthNews';

const HealthInfo = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get('/informations_sanitaires');
        setArticles(response.data);
        setLoading(false);
      } catch (err) {
        setError('Une erreur s\'est produite lors du chargement des informations.');
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  // Group articles by category
  const groupArticlesByCategory = () => {
    const grouped = {
      santeMentale: { category: { id: 'santeMentale', nom: 'Santé Mentale et Bien-être' }, articles: [] },
      santeMaternelleInfantile: { category: { id: 'santeMaternelleInfantile', nom: 'Santé Maternelle et Infantile' }, articles: [] },
      santeSexuelleReproductive: { category: { id: 'santeSexuelleReproductive', nom: 'Santé Sexuelle et Reproductive' }, articles: [] },
      maladiesInfectieuses: { category: { id: 'maladiesInfectieuses', nom: 'Maladies Infectieuses' }, articles: [] },
      factcheckingSante: { category: { id: 'factcheckingSante', nom: 'Factchecking Santé' }, articles: [] },
      droitDeLaSante: { category: { id: 'droitDeLaSante', nom: 'Droit de la Santé' }, articles: [] },
      actuSante: { category: { id: 'actuSante', nom: 'Actu Santé' }, articles: [] },
    };

    articles.forEach((article) => {
      const categoryNom = article.Category.nom.toLowerCase();

      if (categoryNom.includes('mentale')) {
        grouped.santeMentale.articles.push(article);
      } else if (categoryNom.includes('maternelle') || categoryNom.includes('infantile')) {
        grouped.santeMaternelleInfantile.articles.push(article);
      } else if (categoryNom.includes('sexuelle') || categoryNom.includes('reproductive')) {
        grouped.santeSexuelleReproductive.articles.push(article);
      } else if (categoryNom.includes('infectieuse')) {
        grouped.maladiesInfectieuses.articles.push(article);
      } else if (categoryNom.includes('factchecking')) {
        grouped.factcheckingSante.articles.push(article);
      } else if (categoryNom.includes('droit')) {
        grouped.droitDeLaSante.articles.push(article);
      } else if (categoryNom.includes('actualités') || categoryNom.includes('actu')) {
        grouped.actuSante.articles.push(article);
      }
    });

    return grouped;
  };

  const groupedArticles = groupArticlesByCategory();

  const filterArticles = (articles) => {
    if (!searchTerm) return articles;
    return articles.filter((article) =>
      article.titre.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.resume.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.Category.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // Render articles using different components based on category
  const renderArticleComponent = (article) => {
    const categoryNom = article.Category.nom.toLowerCase();

    if (categoryNom.includes('mentale')) {
      return <ChocolateCardMental key={article.id} article={article} />;
    } else if (categoryNom.includes('maternelle') || categoryNom.includes('infantile')) {
      return <ChocolateCardMaternal key={article.id} article={article} />;
    } else if (categoryNom.includes('sexuelle') || categoryNom.includes('reproductive')) {
      return <ChocolateCardReproductive key={article.id} article={article} />;
    } else if (categoryNom.includes('infectieuse')) {
      return <ChocolateCardInfectious key={article.id} article={article} />;
    } else if (categoryNom.includes('factchecking')) {
      return <ChocolateCardFactChecking key={article.id} article={article} />;
    } else if (categoryNom.includes('droit')) {
      return <ChocolateCardHealthLaw key={article.id} article={article} />;
    } else if (categoryNom.includes('actualités') || categoryNom.includes('actu')) {
      return <ChocolateCardHealthNews key={article.id} article={article} />;
    }
    return null; // Fallback for any other cases
  };

  const renderArticlesByCategory = (category, articles) => {
    const filteredArticles = filterArticles(articles);

    return (
      <div key={category.id} className="category-section" style={{ marginTop: '2rem' }}>
        <h3 className="fs-4 fw-bold mb-3" style={{color: "orange"}}>{category.nom}</h3>
        <div className="row g-md-5">
          {filteredArticles.slice(0, 3).map((article) => (
            <div key={article.id} className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-4">
              {renderArticleComponent(article)}
            </div>
          ))}
        </div>
        {articles.length > 3 && (
          <div className="text-end">
            <Link to={`/categories/${articles[0].Category.id}/articles`} className="btn btn-link fs-6 fw-bold">
              Voir plus <i className="pi pi-angle-right"></i>
            </Link>
          </div>
        )}
        <hr />
      </div>
    );
  };

  return (
    <>
     <NavBar />
      <div id="main" style={{ height: '100%' }}>
      
        <div className="bg-light text-dark px-2 py-3 p-lg-4 apropos" style={{marginTop: '8rem'}}>
          <div className="container">
            <h2 className="fs-4 fw-bolder mb-3" style={{fontWeight:'bold', color:'#ffa500'}}>Informations Sanitaires</h2>
            <form className="row g-0 align-items-center" id="form-catalog-search-295742">
              <div className="col-9 col-lg-10">
                <FloatLabel>
                  <InputText
                    id="catalog-search-query-295742"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-control form-control-lg catalog-search-query"
                    autoComplete="off"
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="catalog-search-query-295742">Rechercher</label>
                </FloatLabel>
              </div>
              <div className="col-3 col-lg-2">
                <button
                  type="submit"
                  className="btn btn-block btn-lg btn-secondary catalog-search-products"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="container pt-0 pb-5">
          <div className="w-100">
            {loading ? (
              <p>Chargement des articles...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                {renderArticlesByCategory(groupedArticles.santeMentale.category, groupedArticles.santeMentale.articles)}
                {renderArticlesByCategory(groupedArticles.santeMaternelleInfantile.category, groupedArticles.santeMaternelleInfantile.articles)}
                {renderArticlesByCategory(groupedArticles.santeSexuelleReproductive.category, groupedArticles.santeSexuelleReproductive.articles)}
                {renderArticlesByCategory(groupedArticles.maladiesInfectieuses.category, groupedArticles.maladiesInfectieuses.articles)}
                {renderArticlesByCategory(groupedArticles.factcheckingSante.category, groupedArticles.factcheckingSante.articles)}
                {renderArticlesByCategory(groupedArticles.droitDeLaSante.category, groupedArticles.droitDeLaSante.articles)}
                {renderArticlesByCategory(groupedArticles.actuSante.category, groupedArticles.actuSante.articles)}
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
      
    </>
  );
};

export default HealthInfo;
