import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance, { baseURL } from '../utils/axiosConfig'; 
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import HeaderAdmin from '../partials/header-admin';
import AdminFooter from '../partials/admin-footer';

const PartnerForm = ({ isEdit }) => {
  const [nom, setNom] = useState('');
  const [sigle, setSigle] = useState('');
  const [description, setDescription] = useState('');
  const [representant, setRepresentant] = useState('');
  const [photo, setPhoto] = useState(null); // Stocke le fichier ou l'URL
  const [numero, setNumero] = useState('');
  const [ville, setVille] = useState(''); // Nouvel état pour la ville
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState(null); // Pour stocker le fichier pour l'envoi
  const toast = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams(); 

  useEffect(() => {
    if (isEdit && id) {
      const fetchPartner = async () => {
        try {
          const response = await axiosInstance.get(`/partenaires/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          });
          const { nom, sigle, description, representant, photo, numero, ville } = response.data; // Assurez-vous que la ville est renvoyée ici
          setNom(nom);
          setSigle(sigle);
          setDescription(description);
          setRepresentant(representant);
          setPhoto(photo); // Photo peut être une URL lors de l'édition
          setNumero(numero);
          setVille(ville); // Charger la ville dans le champ de ville
        } catch (error) {
          toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de chargement de l\'association', life: 3000 });
        }
      };
      fetchPartner();
    }
  }, [isEdit, id]);

  const handleImageChange = (e) => {
    const file = e.target.files && e.target.files[0]; // Vérifie si un fichier est sélectionné
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Créer une URL pour l'image locale
      setPhoto(file); // Stocke le fichier pour l'envoi
      setFilename(file); // Sauvegarde le fichier pour l'envoi
    } else {
      console.error("Aucun fichier sélectionné"); // Message d'erreur si aucun fichier n'est sélectionné
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(); // Utilisation de FormData pour gérer le fichier et les autres données
    formData.append('nom', nom);
    formData.append('sigle', sigle);
    formData.append('description', description);
    formData.append('representant', representant);
    formData.append('numero', numero);
    formData.append('ville', ville); // Ajout de la ville dans le FormData

    if (filename) {
      formData.append('image', filename); // Ajout du fichier image si sélectionné
    }

    try {
      if (isEdit) {
        await axiosInstance.put(`/partenaires/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        toast.current.show({ severity: 'success', summary: 'Succès', detail: "L'association a été mise à jour avec succès", life: 3000 });
      } else {
        await axiosInstance.post('/admin-partenaires', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        toast.current.show({ severity: 'success', summary: 'Succès', detail: "L'association a été ajoutée avec succès", life: 3000 });
      }

      // Réinitialiser les champs après soumission
      setNom('');
      setSigle('');
      setDescription('');
      setRepresentant('');
      setPhoto(null);
      setNumero('');
      setVille(''); // Réinitialiser l'état de la ville
      setTimeout(() => navigate('/admin-partners'), 2000); // Rediriger vers la liste des partenaires après l'ajout ou la mise à jour
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: "Échec de l'ajout ou de la mise à jour de l'association", life: 3000 });
    } finally {
      setLoading(false);
    }
  };

  // Liste des villes
  const villes = [
    { name: 'Yaoundé', code: 'YA' },
    { name: 'Douala', code: 'DO' },
    { name: 'Bertoua', code: 'BE' },
  ];

  return (
    <div className='app-wrapper'>  
    <Toast ref={toast} />
        <HeaderAdmin />
        <main className="app-main">
            <div className="app-content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="mb-0">Liste des partenaires</h3>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-end">
                                <li className="breadcrumb-item"><a href="/admin">/admin</a></li>
                                <li className="breadcrumb-item active" aria-current="page">partenaires</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="app-content">
                <div className="container-fluid">
                <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5" style={{ maxWidth: '600px' }}>
                  <div className="container">
                    <div className="card bg-light text-dark" style={{ borderRadius: '8px',  marginTop:"7rem" }}>
                      <div className="card-body p-4 p-md-5 text-center">
                        <h1 className="h2 fw-bold mb-4">{isEdit ? 'Mettre à jour une association' : 'Ajouter une association'}</h1>
                        <form onSubmit={handleSubmit}>
                          <div className="mb-4">
                            <InputText
                              id="nom"
                              value={nom}
                              onChange={(e) => setNom(e.target.value)}
                              required
                              className="form-control form-control-lg"
                              placeholder="Nom de l'association"
                            />
                          </div>
                          <div className="mb-4">
                            <InputText
                              id="sigle"
                              value={sigle}
                              onChange={(e) => setSigle(e.target.value)}
                              required
                              className="form-control form-control-lg"
                              placeholder="Sigle de l'association"
                            />
                          </div>
                          <div className="mb-4">
                            <InputText
                              id="description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              className="form-control form-control-lg"
                              placeholder="Description de l'association"
                            />
                          </div>
                          <div className="mb-4">
                            <InputText
                              id="representant"
                              value={representant}
                              onChange={(e) => setRepresentant(e.target.value)}
                              required
                              className="form-control form-control-lg"
                              placeholder="Leader d'association"
                            />
                          </div>
                          <div className="mb-4">
                            <Dropdown
                              value={ville}
                              onChange={(e) => setVille(e.value)}
                              options={villes}
                              optionLabel="name"
                              optionValue='name'
                              placeholder="Sélectionnez une ville"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div className="mb-4">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              className="form-control form-control-lg"
                            />
                          </div>
                          {photo && (
                            <div className="mb-4">
                              <img
                                src={typeof photo === 'string' && photo.length > 0 
                                  ? `${baseURL}/${photo}` 
                                  : URL.createObjectURL(photo)} // Créez un objet URL si c'est un fichier
                                alt="Aperçu de la photo"
                                style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }}
                              />
                            </div>
                          )}
                          <div className="mb-4">
                            <InputText
                              id="numero"
                              value={numero}
                              onChange={(e) => setNumero(e.target.value)}
                              required
                              className="form-control form-control-lg"
                              placeholder="Numéro de l'association"
                            />
                          </div>
                          {loading && <ProgressSpinner style={{ width: '50px', height: '50px' }} />}
                          <Button
                            type="submit"
                            label={isEdit ? 'Mettre à jour' : 'Ajouter'}
                            icon="pi pi-check"
                            className="p-button p-button-primary p-button-lg w-100"
                            disabled={loading}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
                </div>
            </div>
        </main>
        <AdminFooter />
    </div>
  );
};

export default PartnerForm;
