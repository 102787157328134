import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from './../utils/axiosConfig';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import ChocolateCardFactChecking from './ChocolateCardFactChecking';
import ChocolateCardInfectious from './ChocolateCardInfectious';
import ChocolateCardMaternal from './ChocolateCardMaternal';
import ChocolateCardMental from './ChocolateCardMental';
import ChocolateCardReproductive from './ChocolateCardReproductive';
import ChocolateCardHealthLaw from './ChocolateCardHealthLaw';
import ChocolateCardHealthNews from './ChocolateCardHealthNews';

const CategoryPage = () => {
  const { categoryId } = useParams();
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchCategoryArticles = async () => {
      try {
        const response = await axios.get(`/categories/${categoryId}/articles`);
        setArticles(response.data.articles);
        setFilteredArticles(response.data.articles); // Initialiser les articles filtrés
        setCategory(response.data.category);
        setLoading(false);
      } catch (err) {
        setError('Une erreur s\'est produite lors du chargement des articles.');
        setLoading(false);
      }
    };

    fetchCategoryArticles();
  }, [categoryId]);

  // Fonction de filtrage des articles
  const filterArticles = (term) => {
    if (!term) {
      setFilteredArticles(articles);
    } else {
      const lowerCaseTerm = term.toLowerCase();
      const filtered = articles.filter((article) =>
        article.titre.toLowerCase().includes(lowerCaseTerm) ||
        article.resume.toLowerCase().includes(lowerCaseTerm)
      );
      setFilteredArticles(filtered);
    }
  };

  // Écouteur de changement sur le terme de recherche
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterArticles(term);
  };

  
  // Fonction pour catégoriser et rendre les articles selon la catégorie
  const renderArticleByCategory = (article) => {
    if (!category || !category.nom) {
      return null;
    }
    const categoryNom = category.nom.toLowerCase();

      if (categoryNom.includes('mentale')) {
        return <ChocolateCardMental key={article.id} article={article} />;
    } else if (categoryNom.includes('maternelle') || categoryNom.includes('infantile')) {
        return <ChocolateCardMaternal key={article.id} article={article} />;
    } else if (categoryNom.includes('sexuelle') || categoryNom.includes('reproductive')) {
        return <ChocolateCardReproductive key={article.id} article={article} />;
    } else if (categoryNom.includes('infectieuse')) {
        return <ChocolateCardInfectious key={article.id} article={article} />;
    } else if (categoryNom.includes('factchecking')) {
        return <ChocolateCardFactChecking key={article.id} article={article} />;
    } else if (categoryNom.includes('droit')) {
        return <ChocolateCardHealthLaw key={article.id} article={article} />;
    } else if (categoryNom.includes('actualités') || categoryNom.includes('actu')) {
        return <ChocolateCardHealthNews key={article.id} article={article} />;
    }
    return null; // Fallback for any other cases
    };

    

 
  return (
    <>
      <NavBar />
      <div id="main" style={{ height: '100%' }}>
        <div className="container pt-4 pb-5">
          {loading ? (
            <p>Chargement des articles...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              <h2 className="fs-4 fw-bold mb-3 apropos" style={{marginTop: "8rem",fontWeight:'bold', color:'#ffa500'}}>{category?.nom}</h2>

              {/* Barre de recherche */}
              <div className="mb-4">
                <FloatLabel>
                  <InputText
                    id="search-articles"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="form-control form-control-lg"
                    autoComplete="off"
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="search-articles">Rechercher des articles</label>
                </FloatLabel>
                <div className="col-3 col-lg-2">
                  <button
                    type="submit"
                    className="btn btn-block btn-lg btn-secondary catalog-search-products"
                    style={{
                      marginLeft: '67.8rem',
                      marginTop: "-2.83rem",
                      zIndex: 1,
                      position: 'relative',
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '0px'
                    }}
                    onClick={(e) => {
                      e.preventDefault(); // Empêcher le rechargement de la page
                    }}
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>

              <div className="row g-md-5 mt-3">
                {filteredArticles.map((article) => (
                  <div key={article.id} className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-4 d-flex align-self-stretch">
                    {renderArticleByCategory(article)}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CategoryPage;
