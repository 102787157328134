import React from 'react';
import './ChocolateCard.css';  // Optional, for styling
import { baseURL } from '../utils/axiosConfig';
import { Link } from 'react-router-dom';

const ChocolateCardReproductive = ({ article }) => {  // Recevez l'article comme prop
  return (
    <div id="main" style={{ height: 'auto' }}>
        <div className="container pt-4 pb-5">
        <div className="row g-md-5 ">
        <div className="card bg-light text-dark thumbnail pe-pointer ajax-link-debug">
         <div className="ripple ripple-surface-light">
          <div>
            <Link to={`/healthDetail/${article.id}`} title={article.titre}>
              <img 
                src={`${baseURL}${article.image_url}`}  
                className="img-full" 
                alt={article.titre} 
              />
            </Link>
          </div>
          <div className="card-body">
            <h2 className="fs-5 mb-2">
              <Link to={`/healthDetail/${article.id}`} className="text-dark fw-bolder">{article.titre}</Link>
            </h2>
            <div className="fs-7 mb-2" style={{color:"#269e4a"}} >{article.Category.nom}</div>
            <div className="fs-7 mb-2" style={{color:"#269e4a"}} >Source: {article.source}</div>
            <p className="fs-7">{article.resume}</p>
            <Link to={`/healthDetail/${article.id}`} className="fs-7">En savoir plus</Link>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default ChocolateCardReproductive;