import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';
import axios, { baseURL } from './../utils/axiosConfig';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';
import { FileUpload } from 'primereact/fileupload';

const sexes = [
  { label: 'Homme', value: 'Homme' },
  { label: 'Femme', value: 'Femme' },
  { label: 'Autre', value: 'Autre' }
];

const ProfileEdit = () => {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [userInf, setUserInf] = useState({
    pseudo: '',
    nom: '',
    prenom: '',
    email: '',
    sexe: '',
    date_de_naissance: null,
    numero_de_telephone: '',
    service: '',
    password: '',
    nouveau_mot_de_passe: '',
  });
  const [services, setServices] = useState([]);
  const [role, setRole] = useState('');
  const navigate = useNavigate();
  const imgRef = useRef(null);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user_informations'));
    if (userData) {
      setUserInf(userData);
      setRole(userData.role);
    }

    const fetchServices = async () => {
      try {
        const response = await axios.get('/services');
        const serviceOptions = response.data.map(service => ({ label: service.nom, value: service.id }));
        setServices(serviceOptions);
      } catch (error) {
        console.error('Erreur lors de la récupération des services:', error);
      }
    };

    fetchServices();
  }, []);

  const handleChange = (event) => {
    setUserInf({ ...userInf, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!userInf.date_de_naissance){
      toast.current.show({
        severity: 'error',
        summary: 'Requis',
        detail: "Vous devez fournir une date de naissance.",
        life: 3000
      });
      return;
    }
    setLoading(true);

    try {
      const form = new FormData();
      form.append("pseudo", userInf.pseudo);
      form.append("nom", userInf.nom);
      form.append("prenom", userInf.prenom);
      form.append("email", userInf.email);
      form.append("sexe", userInf.sexe);
      form.append("date_de_naissance", userInf.date_de_naissance);
      form.append("numero_de_telephone", userInf.numero_de_telephone);
      form.append("service", userInf.service);
      if (file) form.append("photo", file);
      if (userInf.password && userInf.nouveau_mot_de_passe) {
        form.append("mot_de_passe", userInf.password);
        form.append("nouveau_mot_de_passe", userInf.nouveau_mot_de_passe);
      }

      


      const response = await axios.put("/profil", form, 
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type':'multipart/form-data'
          }
        }
      );
      localStorage.setItem("user_informations", JSON.stringify(response.data.utilisateur));
      toast.current.show({
        severity: 'success',
        summary: 'Succès',
        detail: 'Mise à jour effectuée avec succès !',
        life: 3000
      });
      // setTimeout(() => navigate("/dashboard"), 2000);
    } catch (error) {
      console.error("Erreur lors de la mise à jour", error);
      toast.current.show({
        severity: 'error',
        summary: 'Erreur',
        detail: "Une erreur est survenue lors de la mise à jour.",
        life: 3000
      });
    } finally {
      setLoading(false);
    }
  };



  return (
    <div>
      <Toast ref={toast} />
      <div id="main" style={{ height: '100%' }}>
      <NavBar />
        <section className="pt-3 mt-4 pt-md-5 ms-auto me-auto pb-3 pb-md-5" style={{ maxWidth: '600px', minHeight: '100vh' }}>
          <div className="container">
            <Card className="bg-light text-dark" style={{ borderRadius: '0' }}>
              <div className="card-body p-4 p-md-5">
                <h1 className="h3 fw-bold mb-4 text-uppercase text-center">Modifier le profil</h1>

                <div className="mb-4">
                    <label>Nom :</label>
                    <InputText 
                    name="nom" 
                    value={userInf.nom} 
                    onChange={handleChange} 
                    className="p-inputtext-lg w-100" 
                    placeholder="Nom *" 
                  />
                </div>

                <div className="mb-4">
                    <label>Prenom :</label>
                    <InputText 
                    name="prenom" 
                    value={userInf.prenom} 
                    onChange={handleChange} 
                    className="p-inputtext-lg w-100" 
                    placeholder="Prénom *" 
                  />
                </div>

                <div className="mb-4">
                    <label>Email :</label>
                    <InputText 
                    name="email" 
                    value={userInf.email} 
                    onChange={handleChange} 
                    className="p-inputtext-lg w-100" 
                    placeholder="Email *" 
                    disabled 
                  />
                </div>

                {role === 'Medecin' && (
                  <div className="mb-4">
                    <label>Service :</label>
                    <Dropdown 
                      name="service" 
                      value={userInf.service} 
                      options={services} 
                      onChange={(e) => setUserInf({ ...userInf, service: e.target.value })} 
                      className="w-100" 
                      placeholder="Sélectionnez votre service *" 
                    />
                  </div>
                )}

                {role === 'Patient' && (
                  <div className="mb-4">
                    <label>Pseudo :</label>
                  <InputText 
                    name="pseudo" 
                    value={userInf.pseudo} 
                    onChange={handleChange} 
                    className="p-inputtext-lg w-100" 
                    placeholder="Pseudo *" 
                    disabled 
                  />
                </div>
                )}

                <div className="mb-4">
                    <label>Sexe :</label>
                    <Dropdown 
                    name="sexe" 
                    value={userInf.sexe} 
                    options={sexes} 
                    onChange={(e) => setUserInf({ ...userInf, sexe: e.target.value })} 
                    className="w-100" 
                    placeholder="Sexe *" 
                  />
                </div>

                <div className="mb-4">
                    <label>Date de Naissance :</label>
                    <Calendar 
                    name="date_de_naissance" 
                    value={userInf.date_de_naissance} 
                    onChange={(e) => setUserInf({ ...userInf, date_de_naissance: e.target.value })} 
                    className="w-100 p-inputtext-lg" 
                    placeholder="Date de Naissance *" 
                  />
                </div>


                {/* Upload de la photo */}
                <div className="mt-4 mb-4">
                    <label>Photo de profil</label>

                    {/* <input  name="photo"  onChange={ handlePhotoUpload} accept="image/*"  /> */}
                    
                      <Card 
                          title="Image"
                          className="image-card"
                          style={{ position: 'relative', width: '100%' }}
                      >
                          <img src={file ? URL.createObjectURL(file) : baseURL + userInf.photo} 
                              alt="uploaded" 
                              style={{ width: '100%' }} />
                          <div style={{ position: 'absolute', bottom: 0, left: 0, display: 'flex', justifyContent: 'space-between', width: '100%', background: 'rgba(0, 0, 0, 0.6)' }}>
                              <Button icon="pi pi-trash" className="p-button-danger" rounded onClick={(e)=>{setUserInf({...userInf, photo:''}); setFile(null)}} />
                              <input type="file" accept="image/*" style={{display:"none"}}  ref={imgRef} onChange={(e)=>setFile( e.target.files[0])} />
                              <Button type="button" icon="pi pi-pencil" className="p-button-warning" rounded onClick={()=>imgRef.current.click()}/>
                          </div>
                      </Card>
                </div>

                <Divider style={{paddingTop:"45px"}} />
                <h3 className="text-center mt-4 mb-4">Changer le mot de passe</h3>

                <div className="mb-4">
                  <Password 
                  lang='fr'
                    name="password" 
                    value={userInf.password} 
                    onChange={handleChange} 
                    className="p-inputtext-lg w-100" 
                    placeholder="Mot de passe actuel" 
                    toggleMask 
                  />
                </div>

                <div className="mb-4">
                  <Password 
                    name="nouveau_mot_de_passe" 
                    value={userInf.nouveau_mot_de_passe} 
                    onChange={handleChange} 
                    className="p-inputtext-lg w-100" 
                    placeholder="Nouveau mot de passe" 
                    toggleMask 
                  />
                </div>

                {loading && <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration="1s" />}

                <Button 
                  label="Enregistrer les modifications" 
                  icon="pi pi-check" 
                  className="p-button-secondary p-button-lg w-100" 
                  onClick={handleSubmit} 
                />

              </div>
            </Card>
          </div>
        </section>
      <Footer />
      </div>
    </div>
  );
};

export default ProfileEdit;
