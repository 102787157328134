import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import HeaderAdmin from '../partials/header-admin';
import AdminFooter from '../partials/admin-footer';


const UpdateQuestionGroupPage = () => {
  const [name, setName] = useState('');
  const [advice, setAdvice] = useState('');
  const [selectedQuizz, setSelectedQuizz] = useState(null);
  const [quizzList, setQuizzList] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuizzList = async () => {
      try {
        const response = await axiosInstance.get('/quizz/');
        setQuizzList(response.data.map(quizz => ({ label: quizz.name, value: quizz.id })));
      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de chargement des quizz', life: 3000 });
      }
    };
    fetchQuizzList();
  }, []);

  useEffect(() => {
    const fetchQuestionGroup = async () => {
      try {
        const response = await axiosInstance.get(`/questiongroup/${id}`,
        {
          headers:{
            'Authorization': `Bearer ${localStorage.getItem('token')}` // Assurez-vous d'avoir le token dans le localStorage
          }
        });
        
        setName(response.data.name);
        setAdvice(response.data.advice);
        setSelectedQuizz(response.data.QuizzId);
      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de chargement du groupe de questions', life: 3000 });
      }
    };
    fetchQuestionGroup();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedGroup = {
      name,
      advice,
      QuizzId: selectedQuizz,
    };

    try {
      await axiosInstance.put(`/questiongroup/${id}/`,
      {
        headers:{
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Assurez-vous d'avoir le token dans le localStorage
        }
      },
      updatedGroup);
      toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Groupe de questions mis à jour', life: 3000 });
      navigate('/questiongroups'); // Rediriger vers la liste des groupes de questions ou ailleurs
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de la mise à jour du groupe', life: 3000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
         <div className="app-wrapper">
        <HeaderAdmin />
      <div id="main" style={{ height: '100vh' }}>
        <Toast ref={toast} />
        <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5" style={{ maxWidth: '600px' }}>
          <div className="container">
            {/* Formulaire pour mettre à jour un Groupe de Questions */}
            <div className="card bg-light text-dark mb-4" style={{ borderRadius: '8px' }}>
              <div className="card-body p-4 p-md-5 text-center">
                <h1 className="h2 fw-bold mb-4">Mettre à jour un groupe de questions</h1>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <InputText
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      className="form-control form-control-lg"
                      placeholder="Nom du groupe"
                    />
                  </div>
                  <div className="mb-4">
                    <InputText
                      id="advice"
                      value={advice}
                      onChange={(e) => setAdvice(e.target.value)}
                      className="form-control form-control-lg"
                      placeholder="Conseil à afficher après les réponses d'un groupe (optionnel)"
                    />
                  </div>
                  <div className="mb-4">
                    <Dropdown
                      value={selectedQuizz}
                      options={quizzList}
                      onChange={(e) => setSelectedQuizz(e.value)}
                      placeholder="Sélectionner un Quizz"
                      className="w-100"
                      required
                    />
                  </div>
                  {loading && <ProgressSpinner style={{ width: '50px', height: '50px' }} />}
                  <Button
                    type="submit"
                    label="Mettre à jour Groupe"
                    icon="pi pi-check"
                    className="p-button p-button-primary p-button-lg w-100"
                    disabled={loading}
                  />
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AdminFooter />
      </div>
    </>
  );
};

export default UpdateQuestionGroupPage;
