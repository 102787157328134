import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import "./WellnessQuiz.css";
import { useNavigate, useParams } from "react-router-dom"; // Importation de useParams pour récupérer l'id du quiz
import Footer from "../partials/footer";
import { Dialog } from "primereact/dialog";
import NavBar from "../partials/nav";
import axios from "../utils/axiosConfig"; // Import axios pour faire des appels à l'API

const WellnessQuiz = () => {
  const [messages, setMessages] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [step, setStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [quizFinished, setQuizFinished] = useState(false);
  const [questionGroups, setQuestionGroups] = useState([]); 
  const [questionsDisplayed, setQuestionsDisplayed] = useState(new Set()); // Utiliser un Set pour suivre les questions affichées
  const toast = React.useRef(null);
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { quizId } = useParams();
  const userId = JSON.parse(localStorage.getItem('user_informations'))?.id;

  // Charger les données du quiz depuis l'API
  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const response = await axios.get(`/quiz/${quizId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const filteredGroups = response.data.questionGroups.filter(group =>
          group.questions.length > 0 && group.questions.every(q => Array.isArray(q.options) && q.options.length > 0)
        );

        if (response.data.questionGroups.length !== filteredGroups.length) {
          console.warn("Certaines questions ou groupes ne sont pas valides. Elles ont été ignorées.");
        }

        setQuestionGroups(filteredGroups);

        if (filteredGroups.length > 0) {
          const firstQuestion = filteredGroups[0].questions[0];
          setMessages([{ sender: "bot", text: firstQuestion.question }]);
          setQuestionsDisplayed(prev => new Set(prev).add(firstQuestion.id)); // Ajout de l'ID de la question affichée
        }
      } catch (error) {
        console.error("Erreur lors du chargement du quiz", error);
      }
    };
    fetchQuiz();
  }, [quizId]);

  const getCurrentQuestion = () => {
    const group = questionGroups[currentGroupIndex];
    return group?.questions[step] || {};
  };

  const Backpage = () => {
    navigate('/quizzList');
  };

  const handleNext = async () => {
    const currentQuestion = getCurrentQuestion();

    if (selectedOptions[currentQuestion.id]) {
      setMessages(prevMessages => [
        ...prevMessages,
        { sender: "user", text: selectedOptions[currentQuestion.id] }
      ]);

      try {
        await axios.post(`/quizz/${quizId}/question/${currentQuestion.id}/submit`, {
          utilisateurId: userId,
          reponse: selectedOptions[currentQuestion.id],
          questionNom: currentQuestion.question,  
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        // Passer à la question suivante
        if (step < questionGroups[currentGroupIndex].questions.length - 1) {
          setStep(prevStep => prevStep + 1);
          setMessages(prevMessages => [
            ...prevMessages,
            { sender: "bot", text: questionGroups[currentGroupIndex].questions[step + 1].question }
          ]);
        } else {
          const advice = getGroupAdvice(currentGroupIndex);
          setMessages(prevMessages => [
            ...prevMessages,
            { sender: "bot", text: advice }
          ]);

          if (currentGroupIndex < questionGroups.length - 1) {
            setCurrentGroupIndex(prevGroupIndex => prevGroupIndex + 1);
            setStep(0);
            setMessages(prevMessages => [
              ...prevMessages,
              { sender: "bot", text: questionGroups[currentGroupIndex + 1].questions[0].question }
            ]);
          } else {
            setQuizFinished(true);
            setMessages(prevMessages => [
              ...prevMessages,
              { sender: "bot", text: "Merci d'avoir complété le quiz. Nous espérons que le quiz vous a été utile !" }
            ]);
          }
        }
        // Mise à jour de la barre de progression
        const totalQuestions = questionGroups.flatMap(g => g.questions).length;
        const completedQuestions = questionGroups.slice(0, currentGroupIndex).reduce((sum, g) => sum + g.questions.length, 0) + step + 1;
        setProgress((completedQuestions / totalQuestions) * 100);
        setSelectedOptions({});
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Erreur",
          detail: "Échec de la soumission de la réponse.",
        });
        console.error("Erreur lors de la soumission de la réponse", error);
      }
    } else {
      toast.current.show({ severity: "warn", summary: "Attention", detail: "Veuillez sélectionner une option avant de continuer." });
    }
  };


  const getGroupAdvice = (groupIndex) => {
    const group = questionGroups[groupIndex];
    return group.advice;
  };

  const handleOptionSelect = (option) => {
    const currentQuestion = getCurrentQuestion();
    setSelectedOptions({
      ...selectedOptions,
      [currentQuestion.id]: option
    });
  };

  const renderOptions = () => {
    const currentQuestion = getCurrentQuestion();

    if (!currentQuestion.options || !Array.isArray(currentQuestion.options) || currentQuestion.options.length === 0) {
      console.error("La question n'a pas d'options valides :", currentQuestion);
      return <p className="error-message">Cette question n'a pas d'options disponibles.</p>;
    }

    return currentQuestion.options.map((option, index) => (
      <div key={index} className="option-item">
        <label>
          <input
            type={currentQuestion.type}
            name={currentQuestion.id}
            value={option}
            checked={selectedOptions[currentQuestion.id] === option}
            onChange={() => handleOptionSelect(option)}
          />
          {option}
        </label>
      </div>
    ));
  };

  const handleFinish = () => {
    setShowSuccessModal(true);

    setTimeout(() => {
      setShowSuccessModal(false);
      navigate('/quizzList');
    }, 5000);
  };

  const renderMessages = () => {
    return messages.map((msg, index) => (
      <div key={index} className={`message ${msg.sender === "bot" ? "bot-message" : "user-message"}`}>
        <div className="message-content">
          <p>{msg.text}</p>
        </div>
      </div>
    ));
  };

  return (
    <>
      <NavBar />
      <div className="quiz-container">
        <Toast ref={toast} />
        <div className="return-button">
          <Button
            icon="pi pi-arrow-left"
            className="p-button-rounded p-button-text"
            onClick={() => Backpage()}
          />
        </div>
        <div className="p-col-12 w-100">
          <Card
            className="chat-card w-75 apropos"
            style={{ marginLeft: '12rem', marginTop: '5rem' }}
            header={<div className="card-header-style" style={{fontWeight:'bold', color:'#ffa500'}}>Évaluation de bien-être</div>}
          >
            <div className="chat-box">
              {renderMessages()}
              {!quizFinished && (
                <div className="options-container">
                  {renderOptions()}
                </div>
              )}
            </div>
            <ProgressBar value={progress} className="p-mb-3" />
            <div className="input-container">
              {!quizFinished ? (
                <Button
                  label="Suivant"
                  icon="pi pi-check"
                  onClick={handleNext}
                  className="p-mt-4 p-button-warning mt-4"
                />
              ) : (
                <Button
                  label="Terminer"
                  icon="pi pi-check"
                  onClick={handleFinish}
                  className="p-mt-4 p-button-success mt-4"
                />
              )}
            </div>
          </Card>
        </div>
      </div>
      <Footer />

      <Dialog
        header="Merci d'avoir complété le quiz !"
        visible={showSuccessModal}
        style={{ width: "50vw" }}
        footer={
          <Button
            label="OK"
            icon="pi pi-check"
            onClick={() => navigate("/quizzList")}
          />
        }
        onHide={() => setShowSuccessModal(false)}
      >
        <p>Votre évaluation a été soumise. Vous recevrez bientôt un compte rendu personnalisé.</p>
      </Dialog>
    </>
  );
};

export default WellnessQuiz;
