import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import axios from './../utils/axiosConfig';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';

const ResetRequest = () => {
  const [email, setEmail] = useState('');
  const toast = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/password-reset', { email });

      toast.current.show({ severity: 'success', summary: 'Succès', detail: response.data.message, life: 3000 });
      setEmail('');
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la demande de réinitialisation', life: 3000 });
    }
  };

  return (
    <>
      <NavBar />
      <div className="container" style={{ padding: '2rem 0', minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Toast ref={toast} />
        <h2 style={{fontWeight:'bold', color:'#ffa500'}}>Demande de réinitialisation de mot de passe</h2>
        <form onSubmit={handleSubmit} className="p-fluid">
          <div className="p-field">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Entrer votre addresse email'
              className="p-inputtext"
            />
          </div>
          <Button label="Envoyer" type="submit" className="p-button mt-5" />
        </form>
        
      </div>
      <Footer />
    </>
  );
};

export default ResetRequest;
