import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance, { baseURL } from '../utils/axiosConfig'; 

const Assistance = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [partenaires, setPartenaires] = useState([]);
  const [groupedPartenaires, setGroupedPartenaires] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate(); // Correction: utiliser la fonction navigate
  // Exemple de données pour le carrousel avec couleurs et numéros d'urgence
  const items = [
    { color: '#FF6347', number: 'Yoheda: +237687113955' }, // Tomate rouge
    { color: '#4682B4', number: 'Pompiers: 18' }, // Bleu acier
    { color: '#32CD32', number: 'Urgences: 15' }, // Vert lime
    { color: '#FF4500', number: 'SAMU: 112' }, // Jaune or
  ];

    // Fonction pour vérifier si l'utilisateur est authentifié
  const isAuthenticated = () => {
    return localStorage.getItem('token') !== null;
  };

  // Fonction pour gérer la redirection en fonction de l'authentification
  const handleRdvClick = () => {
    if (isAuthenticated()) {
      navigate('/rdv'); // Redirige vers la page de rendez-vous si authentifié
    } else {
      navigate('/login'); // Redirige vers la page de login sinon
    }
  };

  // Fonction pour définir l'élément à afficher dans le carrousel
  const itemTemplate = (item) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '8rem',
          width: '80%',
          maxWidth: '2500px', // Limite maximale pour éviter trop de largeur sur les grands écrans
          backgroundColor: item.color, // Couleur de fond
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          color: '#FFFFFF', // Couleur du texte
          fontSize: '1.5rem', // Taille du texte
          fontWeight: 'bold', // Poids du texte
          textAlign: 'center', // Alignement du texte
          padding: '20px', // Padding interne pour éviter que le texte touche les bords
          margin: '0 auto', // Centrage automatique
        }}
      >
        {item.number}
      </div>
    );
  };


  useEffect(() => {
    const fetchPartenaires = async () => {
      try {
        const response = await axiosInstance.get('/partenaires', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const partenairesData = response.data;

        // Grouper les partenaires par ville
        const grouped = partenairesData.reduce((acc, partenaire) => {
          const ville = partenaire.ville || 'Autre'; // Assurez-vous d'avoir une ville ou une valeur par défaut
          if (!acc[ville]) acc[ville] = [];
          acc[ville].push(partenaire);
          return acc;
        }, {});

        setGroupedPartenaires(grouped);
        setPartenaires(partenairesData);
        setLoading(false);
      } catch (error) {
        setError('Une erreur s\'est produite lors de la récupération des partenaires.');
        setLoading(false);
      }
    };

    fetchPartenaires();
  }, []);

  // Fonction de filtrage des partenaires
  const filterPartenaires = (partenaires) => {
    if (!searchTerm) return partenaires; // Si pas de terme de recherche, retourner tous les partenaires
    return partenaires.filter((partenaire) =>
      partenaire.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
      partenaire.sigle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      partenaire.representant.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

 // Template pour afficher un partenaire
 const partenaireTemplate = (partenaire) => (
  <div className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-4 d-flex align-self-stretch">
    <div className="card bg-light text-dark thumbnail pe-pointer ajax-link-debug">
      <div className="ripple ripple-surface-light">
        <a href={partenaire.link} title={partenaire.nom}>
          <img 
            src={`${baseURL}/${partenaire.photo}`} 
            className="img-full" 
            alt={partenaire.nom} 
            title={partenaire.nom} 
          />
        </a>
      </div>
      <div className="card-body position-relative">
        <h2 className="fs-5 mb-2 text-h-ellipsis text-h-ellipsis-3">
          <a href={partenaire.link} title={partenaire.nom} className="text-dark fw-bolder">{partenaire.nom} ({partenaire.sigle})</a>
        </h2>
        <h3 className="fs-7 mb-2 fw-lighter" style={{ color: '#249646' }}>
          Leader : {partenaire.representant}
        </h3>
        <div className="fs-7">
          Description : {partenaire.description}
        </div>
        <div className="fake-line-4"></div>
        <a href={partenaire.link} title={partenaire.nom} className="fs-7 position-absolute" style={{ bottom: '1.5rem', left: '1.5rem' }}>Contact : {partenaire.numero}</a>
      </div>
      <div className="fake-line-4">
        <Link to={`/villeinfo/${partenaire.ville}`} title={partenaire.nom} className="fs-7" style={{ display: 'block', marginTop: '0rem', marginLeft:'1.5rem' }}>
          En savoir plus
        </Link>
      </div>
    </div>
  </div>
);
  return (
    <>
            <NavBar />
      <div id="main" style={{ height: 'auto' }}>

        <div className="bg-light text-dark px-2 py-3 p-lg-4 apropos" style={{marginTop:"8rem"}}>
          <div className="container ">
         <h3 className="fs-4 fw-bolder mb-3" style={{fontWeight:'bold', color:'#ffa500'}}>Nous ne sommes pas seuls</h3>
         <h5 className="font-weight-bold" style={{ fontSize: 'medium', fontWeight:'bold', color:'#ffa500', marginLeft: '1.5rem' }}>
           Vous pouvez également leur conctacter
         </h5>
      </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '2rem',
          boxSizing: 'border-box',
        }}
      >
        <Carousel
          value={items}
          itemTemplate={itemTemplate}
          numVisible={3}
          numScroll={2}
          circular
          autoplay={true}
          autoplayInterval={3000}
          transitionInterval={500}
          responsiveOptions={[
            {
              breakpoint: '990px',
              numVisible: 2,
              numScroll: 1,
            },
            {
              breakpoint: '700px',
              numVisible: 1,
              numScroll: 1,
            },
          ]}
          style={{
            width: '92%',
            padding: '0 0px',
            boxSizing: 'border-box',
          }}
          className="custom-carousel"
        />
      </div>
     
       {/* Section d'assistance */}
       <div
        className="container text-center card pt-4 pb-3 mt-3 mb-4"
        style={{
          position: 'relative',
          overflow: 'hidden',
          backgroundImage: 'url("/data/applications/pictures/service-consultation.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px',
        }}
      >
        <div className="w-100" style={{ marginTop: '7.5%', left: '50%', textAlign: 'center' }}>
        <h3 className="text-light" style={{fontWeight:'bold',color:'#ffff'}}>Échanger avec <span style={{color:'#269e4a'}}>un personnel de santé</span></h3>
          <button onClick={handleRdvClick} className="btn btn-lg mb-5 btn-secondary rounded-1 btn-text-transform-1 text-light">
            Prenez un RDV
          </button>
        </div>
      </div>

      <div id="main" style={{ height: 'auto' }}>
        <div className="bg-light text-dark px-2 py-3 p-lg-4">
          <div className="container">
            <h2 className="fs-4 fw-bolder mb-3" style={{fontWeight:'bold', color:'#ffa500'}}>Notre réseau par ville</h2>
            <h5 className="font-weight-bold" style={{ fontSize: 'medium', fontWeight:'bold', color:'#ffa500', marginLeft: '1.5rem' }}>
            Notre oreille auprès de vous
         </h5>
            <form className="row g-0 align-items-center">
              <div className="col-9 col-lg-10">
                <FloatLabel>
                  <InputText
                    id="partner-search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-control form-control-lg"
                    autoComplete="off"
                    style={{ width: '100%' }}
                  />
                  <label htmlFor="partner-search">Rechercher</label>
                </FloatLabel>
              </div>
              <div className="col-3 col-lg-2">
                <button
                  type="submit"
                  className="btn btn-block btn-lg btn-secondary"
                  style={{ marginLeft: '-4px', zIndex: 1, position: 'relative' }}
                  onClick={(e) => {
                    e.preventDefault(); // Empêcher le rechargement de la page
                  }}
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="container pt-0 pb-5">
          {loading ? (
            <p>Chargement des partenaires...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            Object.entries(groupedPartenaires).map(([ville, partenairesVille]) => {
              const filteredPartenaires = filterPartenaires(partenairesVille); // Filtrer les partenaires selon le terme de recherche
              return (
                <div key={ville} className="city-section" style={{ marginTop: '2rem',fontWeight:'bold', color:'#ffa500' }}>
                  <h3 className="fs-4 fw-bold mb-3">{ville}</h3>
                  
                  {/* Afficher les 3 premiers partenaires filtrés */}
                  <div className="row g-md-5" style={{ marginTop: '0rem' }}>
                    {filteredPartenaires.slice(0, 3).map((partenaire) => partenaireTemplate(partenaire))}
                  </div>

                  {/* Icône "Voir plus" à l'extrême droite */}
                  {partenairesVille.length > 3 && (
                    <div className="text-end">
                      <Link to={`/partnertown/${ville}`} className="btn btn-link fs-6 fw-bold">
                        Voir plus <i className="pi pi-angle-right"></i>
                      </Link>
                    </div>
                  )}
                  <hr />
                </div>
              );
            })
          )}
        </div>
        <Footer />
        </div>
     
       
      </div>
     
    </>
  );
};



export default Assistance;
