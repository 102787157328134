import React, { useEffect, useState } from 'react';
import { Card} from 'primereact/card';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from './../utils/axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';

const UserDetails = () => {
    const [user, setUser] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      const fetchUserData = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`/user/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUser(response.data);
        } catch (error) {
          console.error('Erreur lors de la récupération des données utilisateur:', error);
        }
      };
  
      fetchUserData();
    }, [id]);
  
    const handleEdit = () => {
      navigate(`/edit/${id}`);
    };
  
    const handleDelete = async () => {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/user/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate('/users'); // Redirige vers la liste des utilisateurs après la suppression
      } catch (error) {
        console.error('Erreur lors de la suppression de l’utilisateur:', error);
      }
    };
  
    if (!user) {
      return <>  
      {loading && <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration="1s" />}
      </>
    }
  return (
    <>
     <div id="main" style={{ height: '100%' }}>
    <NavBar/>
      <div className="p-col-10 p-md-6 p-grid p-justify-center">
        <h1 style={{fontWeight:'bold', color:'#ffa500'}}>Informations Personnelles</h1>
        <Card >
          <div className="p-d-flex p-jc-between">
            <div className="p-d-flex p-ai-center">
              {user.photo ? (
                <img
                  src={user.photo} // Assurez-vous que cette URL est correcte
                  alt={`${user.nom} ${user.prenom}`}
                  className="user-photo"
                />
              ) : (
                <div className="user-photo-placeholder"><i className="fas fa-user-alt"></i></div>
              )}
              <div className="p-ml-3">
              
                <p><strong>Email : </strong> {user.email}</p>
                <p><strong>numéro de téléphone : </strong> {user.numero_de_telephone}</p>
                {user.role === 'Medecin' && (
                  <>
                  <p><strong>Nom : </strong> {user.nom}</p>
                  <p><strong>Prenom : </strong> {user.prenom}</p>
                  <p><strong>Service:</strong> {user.service}</p>
                  </>
                )}
                {user.role === 'Patient' && (
                  <>
                  <p><strong>Pseudo : </strong> {user.nom}</p>
                    <p><strong>Date de naissance : </strong> {user.date_de_naissance}</p>
                    <p><strong>Sexe:</strong> {user.sexe}</p>
                  </>
                )}
              </div>
            </div>
            <div className="p-d-flex p-ai-center">
              <Button icon={<i className='pi pi-pen-to-square'></i>} label="Éditer" className="p-button-text" onClick={handleEdit} />
              <Button icon={<i className='pi pi-trash'></i>} label="Supprimer" className="p-button-text p-button-danger" onClick={handleDelete} />
            </div>
          </div>
        </Card>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default UserDetails;
