import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Menu } from 'primereact/menu';
import axios, { baseURL } from '../utils/axiosConfig';
import AdminFooter from '../partials/admin-footer';
import HeaderAdmin from '../partials/header-admin';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Avatar } from 'primereact/avatar';

const AdminMedecins = () => {
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [detailsDialogVisible, setDetailsDialogVisible] = useState(false);
    const [mot_de_passe, setMotDePasse] = useState('');
    const [visiblePass, setVisiblePass] = useState(false);
    const toast = useRef(null);
    const menu = useRef(null); // Ref for the menu
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchDoctors();
    }, []);

    const fetchDoctors = async () => {
        try {
            const response = await axios.get('/api/medecins', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setDoctors(response.data);
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Erreur',
                detail: 'Impossible de charger les informations des médecins'
            });
        }
    };

    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        const dayDifference = today.getDate() - birthDate.getDate();
        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            return age - 1;
        }
        return age;
    };

    const handleEditDoctor = (doctorId) => {
        navigate(`/admin/edit-medecin/${doctorId}`);
    };


    const handlePasswordReset = async (doctor) => {
        try {
            await axios.put(`/reset-password`, {id: selectedDoctor?.Utilisateur.id, password: mot_de_passe}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            toast.current.show({
                severity: 'success',
                summary: 'Succès',
                detail: 'Le mot de passe a été réinitialisé avec succès',
                life: 3000
            });
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Erreur',
                detail: 'Erreur lors de la réinitialisation du mot de passe',
                life: 3000
            });
        }
    }

    const handleDeleteDoctor = async () => {
        try {
            await axios.delete(`/api/medecins/${selectedDoctor.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setDoctors(doctors.filter((doctor) => doctor.id !== selectedDoctor.id));
            setDeleteDialogVisible(false);
            toast.current.show({
                severity: 'success',
                summary: 'Succès',
                detail: 'Médecin supprimé avec succès',
                life: 3000
            });
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Erreur',
                detail: 'Erreur lors de la suppression du médecin',
                life: 3000
            });
        }
    };

    const confirmDeleteDoctor = (doctor) => {
        setSelectedDoctor(doctor);
        setDeleteDialogVisible(true);
    };

    const showDetails = (doctor) => {
        setSelectedDoctor(doctor);
        setDetailsDialogVisible(true);
    };

    const showPass = (doctor) => {
        setSelectedDoctor(doctor);
        setVisiblePass(true);
    };

    const hideDeleteDialog = () => {
        setDeleteDialogVisible(false);
    };

    const hideDetailsDialog = () => {
        setDetailsDialogVisible(false);
    };

    const avatarTemplate = (rowData) => {
        return (
            <div className="flex align-items-center" style={{display:"flex", flexDirection:"row", gap:"1rem"}}>
                <Avatar image={baseURL +  rowData.Utilisateur.photo || '/default-avatar.png'} alt="avatar"  shape="circle" />
                <span>{rowData.Utilisateur.nom} {rowData.Utilisateur.prenom}</span>
            </div>
        );
    };

    const menuTemplate = (rowData) => {
        const menuItems = [
            {
                label: 'Détails',
                icon: 'pi pi-info-circle',
                command: () => showDetails(rowData),
            },
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: () => handleEditDoctor(rowData.id),
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-trash',
                command: () => confirmDeleteDoctor(rowData),
            },
            {
                label: 'Réinitialiser mot de passe',
                icon: 'pi pi-refresh',
                command: () => showPass(rowData),
            },
        ];

        return (
            <div className="flex justify-content-center">
                <Menu model={menuItems} popup ref={menu} id={`menu_${rowData.id}`} />
                <Button icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text" onClick={(e) => menu.current.toggle(e)} />
            </div>
        );
    };

    const deleteDialogFooter = (
        <div>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={handleDeleteDoctor} />
        </div>
    );

    const passsDialogFooter = (
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={(e)=>{setVisiblePass(false)}} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-danger" onClick={handlePasswordReset} />
        </div>
    );

    const renderDetailsDialog = () => {
        if (!selectedDoctor) return null;
        
        const { Utilisateur, TrancheHoraire, date_de_naissance } = selectedDoctor;

        return (
            <Dialog visible={detailsDialogVisible} style={{ width: '450px' }} header="Détails du Médecin" onHide={hideDetailsDialog}>
                <div className="doctor-details">
                    <img src={Utilisateur.photo || '/default-avatar.png'} alt="avatar" style={{ width: '100px', borderRadius: '50%' }} />
                    <h3>{Utilisateur.nom} {Utilisateur.prenom}</h3>
                    <p>Age : {calculateAge(date_de_naissance)} ans</p>
                    <p>Email : {Utilisateur.email}</p>
                    <p>Téléphone : {Utilisateur.telephone}</p>
                    <h4>Plages Horaires</h4>
                    <ul>
                        {TrancheHoraire?.map((plage, index) => (
                            <li key={index}>
                                {plage.debut} - {plage.fin}
                            </li>
                        ))}
                    </ul>
                </div>
            </Dialog>
        );
    };

    const footer = (
        <>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
              <li>Minimum 8 caractères</li>
              <li>Au moins une majuscule</li>
              <li>Au moins une minuscule</li>
              <li>Au moins un chiffre</li>
              <li>Au moins un caractère spécial</li>
            </ul>
        </>
    );
    

    return (
        <div className="app-wrapper">
            <HeaderAdmin />
            <main className="app-main">
                <div className="app-content-header">
                    <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                        <h3 className="mb-0">Les Médecins</h3>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="app-content">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <Toast ref={toast} />
                            <h2>Liste des Médecins</h2>
                            <DataTable value={doctors} paginator rows={10} rowsPerPageOptions={[5, 10, 20]} responsiveLayout="scroll">
                                <Column field="avatar" header="Médecin" body={avatarTemplate} />
                                <Column field="Service.nom" header="Service" />
                                <Column field="createdAt" body={(row=> <>{new Date(row.createdAt).toLocaleDateString('fr')}</>)} header="Date d'ajout" />
                                <Column body={menuTemplate} header="Options" />
                            </DataTable>

                            <Dialog visible={deleteDialogVisible} style={{ width: '450px' }} footer={deleteDialogFooter} onHide={hideDeleteDialog}>
                                <div className="confirmation-content">
                                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '2rem' }} />
                                    <span>Êtes-vous sûr de vouloir supprimer le médecin <b>{selectedDoctor && selectedDoctor.nom} {selectedDoctor && selectedDoctor.prenom}</b>?</span>
                                </div>
                            </Dialog>


                            <Dialog visible={visiblePass} style={{ width: '450px' }} footer={passsDialogFooter} onHide={(e)=>{setVisiblePass(false)}}>
                                <div className="confirmation-content">
                                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '2rem' , marginBottom:"3rem"}} />
                                    <span>Enregister<b>{selectedDoctor && selectedDoctor.nom} {selectedDoctor && selectedDoctor.prenom}</b>?</span>

                                    <div className="mb-4">
                                        <Password id="register-password" name="mot_de_passe" header={<div className="font-bold mb-3">Choisissez un mot de passe: </div>}  toggleMask value={mot_de_passe} onChange={(e)=>{setMotDePasse(e.target.value)}} className=" w-100" footer={footer} placeholder="Nouveau mot de passe *" />
                                        </div>
                                </div>
                            </Dialog>

                            {renderDetailsDialog()}
                        </div>
                    </div>
                </div>
            </main>
            <AdminFooter />
        </div>
    );
};

export default AdminMedecins;
