import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Fonction pour vérifier si l'utilisateur est authentifié
const isAuthenticated = () => {
  // Remplacez ceci par votre logique d'authentification réelle
  return localStorage.getItem('token') !== null;
};

// Composant de route privée
const PrivateRoute = ({ children }) => {
  const location = useLocation();

  // Si l'utilisateur est authentifié, affichez les enfants (composant de route protégée)
  // Sinon, redirigez vers la page de connexion
  return isAuthenticated() ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
