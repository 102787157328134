import React, { useState, useRef, useEffect } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog'; // Import Dialog for modal
import { useNavigate } from 'react-router-dom';
import axios, { baseURL } from "../utils/axiosConfig";
import NavBar from "../partials/nav";
import Footer from "../partials/footer";
import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "./RDV.css";
import { Avatar } from "primereact/avatar";

const RDV = () => {
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState({ heureDebut: null, heureFin: null });
    const [searchService, setSearchService] = useState("");
    const [searchDoctor, setSearchDoctor] = useState("");
    const [symptomDescription, setSymptomDescription] = useState("");
    const [availableTimes, setAvailableTimes] = useState([]);
    const [showLoginPrompt, setShowLoginPrompt] = useState(false); // State for modal visibility
    const toast = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get('/services');
                setServices(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des services', error);
            }
        };
        fetchServices();
    }, []);

    useEffect(() => {
        const fetchAvailableTimes = async () => {
            if (selectedDoctor) {
                try {
                    const response = await axios.get(`/medecin/${selectedDoctor.id}/tranches-horaires`);
                    setAvailableTimes(response.data.tranchesHoraires);
                } catch (error) {
                    console.error('Erreur lors de la récupération des créneaux horaires', error);
                }
            }
        };
        fetchAvailableTimes();
    }, [selectedDoctor]);

    useEffect(() => {
        const fetchDoctors = async () => {
            if (selectedService) {
                try {
                    const response = await axios.get(`/medecins/${selectedService.id}`);
                    setDoctors(response.data);
                } catch (error) {
                    console.error('Erreur lors de la récupération des médecins', error);
                }
            }
        };
        fetchDoctors();
    }, [selectedService]);

    const filteredServices = services.filter((service) =>
        service.nom.toLowerCase().includes(searchService.toLowerCase())
    );

    const filteredDoctors = doctors.filter((doctor) =>
        doctor.Utilisateur.nom.toLowerCase().includes(searchDoctor.toLowerCase())
    );
    const nextStep = () => {
        // Check if user is connected
        const userInformations = JSON.parse(localStorage.getItem('user_informations'));
        if (!userInformations) {
            // Show modal if not connected
            setShowLoginPrompt(true);
            // Hide the modal after 3 minutes (180000 ms)
            setTimeout(() => {
                setShowLoginPrompt(false);
            }, 180000); // 3 minutes
        } else {
            setStep(step + 1);
        }
    };
    

    const resetForm = () => {
        setStep(1);
        setSelectedService(null);
        setSelectedDoctor(null);
        setSelectedDate(null);
        setSelectedTime(null);
        setSymptomDescription("");
    };

    const confirmAppointment = async () => {
        setLoading(true);
        try {
            const userInformations = JSON.parse(localStorage.getItem('user_informations'));
            if (!selectedTime) {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Veuillez sélectionner une date et une heure valides. ', life: 3000 });
                setLoading(false);
                return;
            }

            const appointmentDate = new Date(selectedTime.heureDebut);
            const [hourStart, minuteStart] = selectedTime.heureDebut.split(':').map(Number);
            appointmentDate.setHours(hourStart, minuteStart);

            const joursFrancais = { "lundi": 1, "mardi": 2, "mercredi": 3, "jeudi": 4, "vendredi": 5, "samedi": 6, "dimanche": 0 };
            function getDateDuJour(selectedTime) {
                const jourDeLaSemaine = selectedTime.jour.toLowerCase();
                const jourIndex = joursFrancais[jourDeLaSemaine];
                if (jourIndex === undefined) throw new Error("Jour de la semaine invalide : " + selectedTime.jour);
                const aujourdHui = new Date();
                const dateDuJour = new Date(aujourdHui);
                dateDuJour.setHours(0, 0, 0, 0);
                const diff = (jourIndex - dateDuJour.getDay() + 7) % 7;
                dateDuJour.setDate(dateDuJour.getDate() + diff);
                return dateDuJour;
            }
            const endDate = getDateDuJour(selectedTime);
            const [hourEnd, minuteEnd] = selectedTime.heureFin.split(':').map(Number);
            endDate.setHours(hourEnd, minuteEnd);

            const dayOfWeek = appointmentDate.toLocaleDateString('fr-FR', { weekday: 'long' });
            const dayOfMonth = appointmentDate.getDate();
            const year = appointmentDate.getFullYear();
            const formattedTimeStart = `${hourStart}:${minuteStart < 10 ? `0${minuteStart}` : minuteStart}`;
            const formattedTimeEnd = `${hourEnd}:${minuteEnd < 10 ? `0${minuteEnd}` : minuteEnd}`;

            const dateHeure = { dayOfMonth, dayOfWeek, timeStart: formattedTimeStart, timeEnd: formattedTimeEnd, year };

            const response = await axios.post(
                '/rendezvous',
                {
                    date_heure: dateHeure,
                    MedecinId: selectedDoctor.id,
                    notes: symptomDescription,
                    PatientId: userInformations.id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Rendez-vous créé avec succès.', life: 3000 });
            setStep(4);

        } catch (error) {
            console.error('Erreur lors de la création du rendez-vous', error);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la création du rendez-vous.', life: 3000 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <NavBar />
            <div style={{height: "100%"}}>
                <Toast ref={toast} />
                <Dialog 
                    header="Veuillez vous connecter" 
                    visible={showLoginPrompt} 
                    style={{ width: '50vw' }} 
                    onHide={() => {
                        setShowLoginPrompt(false);

                        navigate('/login');  // Redirect to login on modal close
                    }}
                >
                    <p>Vous devez créer un compte ou vous connecter pour prendre un rendez-vous.</p>
                    <Button label="Aller à la page de connexion" onClick={() => navigate('/login')} />
                </Dialog>
            <div className="rdv-container" >
                <div className={`rdv-content ${loading ? "blurred" : ""} apropos`} style={{marginTop: '8rem'}}>
                    {/* Step 1: Service Selection and Symptom Description */}
                    {step === 1 && (
                        <div >
                            <h2>Brève description de votre souci (2 à 3 symptômes ) <span style={{fontSize:'small', color:"red"}}>*</span></h2>
                            <InputTextarea
                                placeholder="Décrivez votre problème ici... *"
                                value={symptomDescription}
                                onChange={(e) => setSymptomDescription(e.target.value)}
                                rows={5}
                                style={{ marginBottom: "20px", width: "100%" }}
                            />
                             <h3>Choisir un service <span style={{fontSize:'small', color:"red"}}>*</span></h3>
                            <InputText
                                placeholder="Rechercher un service..."
                                value={searchService}
                                onChange={(e) => setSearchService(e.target.value)}
                                style={{ marginBottom: "20px", width: "100%" }}
                            />
                           
                            <div className="card-grid">
                                {filteredServices.map((service) => (
                                    <Card
                                        key={service.id}
                                        className={`service-card ${selectedService?.id === service.id ? "selected" : ""}`}
                                        onClick={() => setSelectedService(service)}
                                    >
                                        {service.nom}
                                    </Card>
                                ))}
                            </div>
                            <Button
                                label="Suivant"
                                onClick={nextStep}
                                disabled={!selectedService || !symptomDescription}
                                className="next-button"
                            />
                        </div>
                    )}

                    {/* Step 2: Doctor Selection */}
                    {step === 2 && (
                        <div>
                            <h2>Choisissez un prestataire <span style={{fontSize:'small', color:"red"}}>*</span> </h2>
                            <InputText
                                placeholder="Rechercher un prestataire... "
                                value={searchDoctor}
                                onChange={(e) => setSearchDoctor(e.target.value)}
                                style={{ marginBottom: "20px", width: "100%" }}
                            />
                            <div className="card-grid">
                                {filteredDoctors.map((doctor) => (
                                    <Card
                                        key={doctor.Utilisateur.id}
                                        className={`doctor-card ${selectedDoctor?.Utilisateur.id === doctor.Utilisateur.id ? "selected" : ""}`}
                                        style={{ backgroundColor: `${selectedDoctor?.Utilisateur.id === doctor.Utilisateur.id ? "#95c73a" : "initial"}` }}
                                        onClick={() => setSelectedDoctor(doctor)}
                                    >
                                        
                                        <div className="doctor-info flex" style={{display:'flex', flexDirection:"row", justifyContent:"space-around"}}>
                                            <Avatar
                                            image={baseURL+  doctor.Utilisateur.photo}
                                            alt={doctor.Utilisateur.nom}
                                            className="doctor-image"
                                            shape="circle"
                                        />
                                            <h3 style={{alignContent:"center"}}>{doctor.Utilisateur.nom}</h3>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                           
                            <div style={{display:'flex', flexDirection:"row", justifyContent:"space-around"}}>
                                <Button
                                    label="Retour"
                                    onClick={()=>setStep((prevStep)=>prevStep-1)}
                                    disabled={step === 1 && selectedDoctor}
                                    className="next-button"
                                />
                                <Button
                                    label="Suivant"
                                    onClick={nextStep}
                                    disabled={!selectedDoctor}
                                    className="next-button"
                                />
                            </div>
                        </div>
                    )}

                    {/* Step 3: Date and Time Selection */}
                    {step === 3 && (
                        <div>
                            <h2>Choisissez une Date et une Heure</h2>
                            
                           
                                <>
                                    <h6>Créneaux disponibles:</h6>
                                    <div className="time-grid">
                                    {availableTimes.map((time) => (
                                        <Button
                                            key={`${time.jour}-${time.heureDebut}`}
                                            label={`${time.jour} ${time.heureDebut} - ${time.heureFin}`}
                                            className={`time-button ${selectedTime === time ? "selected" : ""}`}
                                            onClick={() => setSelectedTime(time)}
                                        />
                                    ))}

                                    </div>
                                </>
                            
                            
                             <div style={{display:'flex', flexDirection:"row", justifyContent:"space-around"}}>
                                <Button
                                    label="Retour"
                                    onClick={()=>setStep((prevStep)=>prevStep-1)}
                                    disabled={step === 1 && selectedDoctor}
                                    className="next-button"
                                />
                                <Button
                                label="Confirmer le Rendez-vous"
                                onClick={confirmAppointment}
                                disabled={ !selectedTime}
                                className="next-button"
                            />
                            </div>
                        </div>
                    )}

                   {/* Confirmation */}
                    {step === 4 && (
                        <div className="confirmation">
                            <h2>Demande de Rendez-vous Envoyée</h2>
                            <p>
                                Votre demande de rendez-vous a été envoyée avec succès.
                                <br />
                                <strong>Service:</strong> {selectedService.nom}
                                <br />
                                <strong>Médecin:</strong> {selectedDoctor.Utilisateur.nom}
                                <br />
                                <strong>Date:</strong> {selectedDate?.toLocaleDateString("fr-FR")}
                                <br />
                                <strong>Heure:</strong> {`${selectedTime.heureDebut} - ${selectedTime.heureFin}`}
                                <br />
                                <strong>Description du problème:</strong> {symptomDescription}
                            </p>
                            <Button
                                label="Prendre un autre rendez-vous"
                                onClick={resetForm}
                                className="next-button"
                            />
                        </div>
                    )}

                </div>

                {/* Loader */}
                {loading && (
                    <div className="loader-bar">
                        <div className="progress-line"></div>
                    </div>
                )}
            </div>
            <Footer />
            </div>
        </>
    );
};

export default RDV;
