import React from 'react';
import './ArticleCard.css';  // Optional, for styling
import { baseURL } from '../utils/axiosConfig';
import { Link } from 'react-router-dom';

const ChocolateCardHealthNews = ({ article }) => { // Ajout de la prop article
  return (
    <div className="article-card">
      {/* Image */}
      <div className="image-container">
      <img 
        src={`${baseURL}${article.image_url}`} // Utiliser l'image de l'article ou une image par défaut
        alt={article.titre} 
        className="book-image"
      />
    </div>

      {/* Title */}
      <h2 className="article-title">
        {article.titre}  {/* Afficher le titre de l'article */}
      </h2>

      {/* Author and Date */}
      <div className="meta-info">
      <i className="fas fa-hospital"></i>
        <span className="author">{article.NomFormationSanitaire || 'Hopital génerale de Yaoundé'}</span>
        
      </div>
       {/* Author and Date */}
       <div className="meta-info">
        <span className="author">
          <span className="icon-users"></span>
          {article.source}  {/* Assurez-vous que l'article a une propriété 'auteur' */}
        </span>
        <span className="date"> {new Date(article.createdAt).toLocaleDateString('fr-FR')}  {/* Formater la date */}</span>
      </div>

      {/* Description */}
      <p className="description">
        {article.resume}
      </p>
      <Link to={`/healthDetail/${article.id}`} className="fs-7" style={{color: 'rgb(143, 202, 238)'}}>En savoir plus</Link>
    </div>
    
  );
};

export default ChocolateCardHealthNews;
