import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import axios from './../utils/axiosConfig';
import { useParams } from 'react-router-dom';
import Footer from '../partials/footer';
import NavBar from '../partials/nav';

const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const toast = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/reset-password/${token}`, { newPassword });
      toast.current.show({ severity: 'success', summary: 'Succès', detail: response.data.message, life: 4000 });
        setNewPassword('');
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la réinitialisation du mot de passe', life: 3000 });
    }
  };

  return (
    <>
      <NavBar />
      <div className="container" style={{ padding: '2rem 0', minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Toast ref={toast} />
        <h2 style={{fontWeight:'bold', color:'#ffa500'}}>Réinitialiser votre mot de passe</h2>
        <form onSubmit={handleSubmit} className="p-fluid">
          <div className="p-field">
            <label htmlFor="password">Nouveau mot de passe</label>
            <InputText
              id="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type="password"
              placeholder='Entrer le nouveau mot de passe'
              className="p-inputtext"
            />
          </div>
          <Button label="Réinitialiser" type="submit" className="p-button mt-5" />
        </form>
        
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;
