import React, { useState, useRef } from 'react';
import axios from '../utils/axiosConfig'; 
import NavBar from '../partials/nav';
import Footer from '../partials/footer';
import { InputText } from 'primereact/inputtext'; 
import { InputTextarea } from 'primereact/inputtextarea'; 
import { Button } from 'primereact/button'; 
import { Toast } from 'primereact/toast'; 
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css'; 
import 'primeicons/primeicons.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const toast = useRef(null); 

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/send-email', {
        name,
        contact,
        subject,
        message
      });

      if (response.data.success) {
        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Votre message a été envoyé avec succès!', life: 3000 });
        setName('');
        setContact('');
        setSubject('');
        setMessage('');
      } else {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de l\'envoi de votre message.', life: 3000 });
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi du message:', error);
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'envoi de votre message.', life: 3000 });
    }
  };

  return (
    <div>
      <NavBar />
      <Toast ref={toast} />
      <div className="container container-title pt-5 pb-5" style={{height: '100%'}}>
        <h1 className="fs-2 fw-bolder mb-0 apropos" style={{marginTop: '8rem',fontWeight:'bold', color:'#ffa500'}}>Contact</h1>
      </div>
      <div className="container container-title pt-5 pb-5">
        <div className="w-100" style={{ margin: "0 auto" }}>
          <address className="shadow-2-strong p-4 p-lg-5 m-0 rounded bg-light text-dark">
            <strong>Yoheda Care</strong>
            <br />Yoheda&nbsp;Health Organization
            <br />Cameroun, Yaoundé&nbsp;Cameroon, Centre
            <br />Montée Superette &nbsp;Biyem-Assi
            <br /><br />Téléphone : <a href="tel:+237687113955" className="nofollow">+237 6 95 24 49 49</a>
            <br />Email : <a href="mailto:info@yohedahealth.org" className="nofollow">yoheda54@gmail.com</a>	
            <br />Page Facebook: <a href="mailto:info@yohedahealth.org" className="nofollow">https://www.facebook.com/Yoheda/</a>	
          </address>
        </div>
      </div>
      <div className="container pt-0 pb-5">
        <div className="w-lg-100" style={{ margin: "0 auto" }}>
          <h2 className="mb-3">Contactez-nous</h2>
          <form onSubmit={handleSubmit} className="form-user bg-light text-dark px-3 py-4 px-md-5 py-md-5 rounded-3 shadow-2-strong">
            <div className="mb-4">
              <label className="form-label fs-7">Votre prénom <span className="required">*</span></label>
              <InputText 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                placeholder="Entrez votre prénom" 
                required 
                className="w-100" 
              />
            </div>
            <div className="mb-4">
              <label className="form-label fs-7">Votre numéro de téléphone ou adresse email <span className="required">*</span></label>
              <InputText 
                type="email" 
                value={contact} 
                onChange={(e) => setContact(e.target.value)} 
                placeholder="Entrez votre numéro ou email" 
                required 
                className="w-100" 
              />
            </div>
            <div className="mb-4">
              <label className="form-label fs-7">Objet du message <span className="required">*</span></label>
              <InputText 
                value={subject} 
                onChange={(e) => setSubject(e.target.value)} 
                placeholder="Entrez l'objet de votre message" 
                required 
                className="w-100" 
              />
            </div>
            <div className="mb-4">
              <label className="form-label fs-7">Votre message</label>
              <InputTextarea 
                rows={5} 
                value={message} 
                onChange={(e) => setMessage(e.target.value)} 
                placeholder="Écrivez votre message ici..." 
                required 
                className="w-100" 
              />
            </div>
            <Button type="submit" label="Envoyer" className="btn btn-block btn-lg btn-secondary rounded-1 btn-text-transform-1 text-light" />
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
