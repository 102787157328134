
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import HeaderAdmin from '../partials/header-admin';
import AdminFooter from '../partials/admin-footer';

const UpdateQuestionPage = () => {
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState([]);
  const [field, setField] = useState('');
  const [type, setType] = useState('');
  const [next, setNext] = useState({});
  const [optionInput, setOptionInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [questionGroups, setQuestionGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [questionId, setQuestionId] = useState(null);
  const toast = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchQuestionGroups = async () => {
      try {
        const response = await axiosInstance.get('/questiongroups/',
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}` // Assurez-vous d'avoir le token dans le localStorage
          }
        });
        // Assurez-vous que la réponse est un tableau d'objets avec 'id' et 'name'
        setQuestionGroups(response.data.map(group => ({ label: group.name, value: group.id })));
      } catch (error) {
        if (toast.current) {
          toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec du chargement des groupes de questions', life: 3000 });
        }
      }
    };

    const fetchQuestion = async () => {
      try {
        const response = await axiosInstance.get(`/question/${id}/`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}` // Assurez-vous d'avoir le token dans le localStorage
          }
        });
        
        const data = response.data;
        console.log('Données de la question:', data); // Ajoutez cette ligne pour voir les données reçues
        setQuestion(data.question);
        setOptions(Array.isArray(data.options) ? data.options : []); // Assurez-vous que 'options' est un tableau
        setField(data.field);
        setType(data.type);
        setNext(data.next);
        setSelectedGroup(data.questionGroupId);
        setQuestionId(id);
      } catch (error) {
        if (toast.current) {
          toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec du chargement de la question', life: 3000 });
        }
      }
    };

    fetchQuestionGroups();
    if (id) fetchQuestion();
  }, [id]);

  const questionTypes = [
    { label: 'Radio', value: 'radio' },
    { label: 'Checkbox', value: 'checkbox' },
    { label: 'Text', value: 'text' }
  ];

  const handleAddOption = () => {
    if (optionInput.trim() !== '') {
      setOptions([...options, optionInput]);
      setOptionInput('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedQuestion = {
      question,
      options,
      field,
      type,
      next,
      questionGroupId: selectedGroup,
    };

    try {
      await axiosInstance.put(`/question/${questionId}/`,
      {
        headers:{
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      }, updatedQuestion);
      if (toast.current) {
        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Question mise à jour', life: 3000 });
      }
      setTimeout(() => navigate("/questions"), 2000);
    } catch (error) {
      if (toast.current) {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de la mise à jour de la question', life: 3000 });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
         <div className="app-wrapper">
        <HeaderAdmin />
      <div id="main" style={{ height: '100vh' }}>
        <Toast ref={toast} />
        <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5" style={{ maxWidth: '600px' }}>
          <div className="container">
            <div className="card bg-light text-dark" style={{ borderRadius: '8px' }}>
              <div className="card-body p-4 p-md-5 text-center">
                <h1 className="h2 fw-bold mb-4">Modifier la question</h1>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <InputTextarea
                      id="question"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      required
                      rows={3}
                      className="form-control form-control-lg"
                      placeholder="Entrez la question"
                    />
                  </div>
                  <div className="mb-4">
                    <InputText
                      id="optionInput"
                      value={optionInput}
                      onChange={(e) => setOptionInput(e.target.value)}
                      className="form-control form-control-lg"
                      placeholder="Ajouter une option"
                    />
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button p-button-success p-mt-2" onClick={handleAddOption} />
                    <ul className="list-unstyled mt-3">
                      {Array.isArray(options) ? options.map((option, index) => (
                        <li key={index}>{option}</li>
                      )) : null}
                    </ul>
                  </div>
                  <div className="mb-4">
                    <InputText
                      id="field"
                      value={field}
                      onChange={(e) => setField(e.target.value)}
                      required
                      className="form-control form-control-lg"
                      placeholder="Champ"
                    />
                  </div>
                  <div className="mb-4">
                    <Dropdown
                      id="type"
                      value={type}
                      options={questionTypes}
                      onChange={(e) => setType(e.value)}
                      placeholder="Sélectionner un type"
                      required
                      className="w-100"
                    />
                  </div>
                  <div className="mb-4">
                    <Dropdown
                      id="questionGroup"
                      value={selectedGroup}
                      options={questionGroups}
                      onChange={(e) => setSelectedGroup(e.value)}
                      placeholder="Sélectionner un groupe de questions"
                      required
                      className="w-100"
                    />
                  </div>
                  <div className="mb-4">
                    <InputTextarea
                      id="next"
                      value={JSON.stringify(next)}
                      onChange={(e) => {
                        try {
                          setNext(JSON.parse(e.target.value));
                        } catch {
                        //   setNext({});
                        }
                      }}
                      rows={3}
                      className="form-control form-control-lg"
                      placeholder="Logique suivante (JSON)"
                    />
                  </div>
                  {loading && <ProgressSpinner style={{ width: '50px', height: '50px' }} />}
                  <Button
                    type="submit"
                    label="Mettre à jour"
                    icon="pi pi-check"
                    className="p-button p-button-primary p-button-lg w-100"
                    disabled={loading}
                  />
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AdminFooter />
      </div>
    </>
  );
};


export default UpdateQuestionPage;
