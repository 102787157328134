import React from "react";
import { Link } from "react-router-dom";



export default function AdminFooter (){
    return(
        <>
            <footer class="app-footer"> 
                    <div class="float-end d-none d-sm-inline">Une planète unie</div>  <strong>
                        Copyright © 2014-2024&nbsp;
                        <Link to="/" class="text-decoration-none">Yoheda Care</Link>.
                    </strong>
                    All rights reserved.
                    
                </footer> 
            <div class="sidebar-overlay"></div>
        </>
    );
};