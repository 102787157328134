import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../partials/nav';
import Footer from '../partials/footer';
import axios from '../utils/axiosConfig';
import ImageCarousel from './ImageCarousel';
import ChocolateCardFactChecking from './ChocolateCardFactChecking';
import ChocolateCardInfectious from './ChocolateCardInfectious';
import ChocolateCardMaternal from './ChocolateCardMaternal';
import ChocolateCardMental from './ChocolateCardMental';
import ChocolateCardReproductive from './ChocolateCardReproductive';
import ChocolateCardHealthLaw from './ChocolateCardHealthLaw';
import ChocolateCardHealthNews from './ChocolateCardHealthNews';

const Home = () => {
    const [sanitaryInfos, setSanitaryInfos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [rdv, setRdvs] = useState([]);

    useEffect(() => {
        const visitorTracked = localStorage.getItem('visitorTracked');
        const getInf = async () => {
            if (!visitorTracked) {
                await axios.post('/api/visitor-add')
                    .then(() => {
                        localStorage.setItem('visitorTracked', 'true'); // Mark this visitor as tracked
                    })
                    .catch((error) => console.error('Error updating visitor count', error));
            }

            await axios.get('/informations_sanitaires')
                .then(response => {
                    setSanitaryInfos(response.data.slice(0, 3)); // Only the last 3 publications
                    setLoading(false);
                })
                .catch(error => {
                    setError('Erreur lors de la récupération des informations sanitaires');
                    setLoading(false);
                });

                    // Fetch RDVs 
            axios.get("/rendezvous-stats").then((response) => {
                setRdvs(response.data);
            }).catch((error) => {
                setLoading(false);
                console.error(error)});
            };

        getInf();
    }, []);


  
    const renderSanitaryInfo = (sanitaryInf) => {
        const categoryNom = sanitaryInf.Category.nom.toLowerCase();
                
            if (categoryNom.includes('mentale')) {
                return <ChocolateCardMental key={sanitaryInf.id} article={sanitaryInf} />;
            } else if (categoryNom.includes('maternelle') || categoryNom.includes('infantile')) {
                return <ChocolateCardMaternal key={sanitaryInf.id} article={sanitaryInf} />;
            } else if (categoryNom.includes('sexuelle') || categoryNom.includes('reproductive')) {
                return <ChocolateCardReproductive key={sanitaryInf.id} article={sanitaryInf} />;
            } else if (categoryNom.includes('infectieuse')) {
                return <ChocolateCardInfectious key={sanitaryInf.id} article={sanitaryInf} />;
            } else if (categoryNom.includes('factchecking')) {
                return <ChocolateCardFactChecking key={sanitaryInf.id} article={sanitaryInf} />;
            } else if (categoryNom.includes('droit')) {
                return <ChocolateCardHealthLaw key={sanitaryInf.id} article={sanitaryInf} />;
            } else if (categoryNom.includes('actualités') || categoryNom.includes('actu')) {
                return <ChocolateCardHealthNews key={sanitaryInf.id} article={sanitaryInf} />;
            }
            return null; // Fallback for any other cases
            };

    return (
        <>
            <NavBar />
            <div id="main" style={{ height: '100%' }}>
                <div className="pt-0 pb-5" style={{marginTop:'8rem'}}>
                    <div className="w-100" style={{ margin: '0 auto' }}>
                        <ImageCarousel />
                    </div>
                    <div className="container pt-0 pb-5">
                        <div className="mt-5 w-100" style={{ margin: "0 auto", fontWeight: 'bold' }}>
                            <h4  style={{fontWeight:'bold', color:'#ffa500'}}>Les dernières parutions</h4>
                        </div>
                    </div>
                    <div className="container pt-0 pb-5">
                        <div className="w-60" style={{ margin: '0 auto', height: "70%" }}>
                            {loading ? (
                                <p>Chargement...</p>
                            ) : error ? (
                                <p>{error}</p>
                            ) : sanitaryInfos.length > 0 ? (
                                <div className="scroll-container d-flex" style={{ overflowX: 'auto', scrollSnapType: 'x mandatory', gap: '16px' }}>
                                    {sanitaryInfos.map(sanitaryInf => renderSanitaryInfo(sanitaryInf))}
                                </div>
                            ) : (
                                <p>Pas d'information pour le moment</p>
                            )}

                            <div className="col-12 pt-3">
                                <Link
                                    to="/healthInfo"
                                    className="btn btn-lg btn-block btn-secondary rounded-1 btn-text-transform-1 text-light px-1 fs-8"
                                    title="Voir plus d'informations sanitaires"
                                >
                                    Voir plus
                                </Link>
                            </div>
                        </div>
                    </div>


                        {/* Section des statistiques des RDVs */}
                        <div className="container card bg-light py-4">
                            <h2 className="text-center mb-4">Nos statistiques sur les rendez-vous:</h2>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 mb-4">
                                    <div className="small-box text-bg-success text-center p-4">
                                        <div className="inner">
                                            <h3>{rdv?.totalRendezVous}</h3>
                                            <p>Tous</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12 mb-4">
                                    <div className="small-box text-bg-warning text-center p-4">
                                        <div className="inner">
                                            <h3>{rdv?.acceptedRendezVous}</h3>
                                            <p>Acceptés</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12 mb-4">
                                    <div className="small-box text-bg-danger text-center p-4">
                                        <div className="inner">
                                            <h3>{rdv?.refusedRendezVous}</h3>
                                            <p>Refusés</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12 mb-4">
                                    <div className="small-box text-bg-primary text-center p-4">
                                        <div className="inner">
                                            <h3>{rdv?.resolvedProblems}</h3>
                                            <p>Problèmes résolus</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <Link to="/feedback" className="btn btn-lg btn-secondary rounded-1 btn-text-transform-1 text-light" style={{ backgroundColor: "#269e4a" }}>
                                    Voir les témoignages
                                </Link>
                            </div>
                        </div>



                    {/* FAQ Section */}
                    <div className="pt-0 pb-5">
                        <div className="col-button w-100 bg-dark text-light px-4 px-lg-0 pt-5 pb-5 text-center" style={{ margin: "0 auto" }}>
                            <div className="h3 mt-5">Questions fréquemment posées</div>
                            <h6 className="fw-lighter mb-5" style={{ maxWidth: "580px", marginLeft: "auto", marginRight: "auto" }}>
                                <p>Vous-vous demandez ce qui vous arrive lorsque vous avez tel ou tel symptôme ? Ou bien comment faire face à un signe annonciateur donné ?</p>
                            </h6>
                            <Link to="/faq" className="btn btn-lg mb-5 btn-secondary rounded-1 btn-text-transform-1 text-light" style={{ backgroundColor: "#269e4a" }}>
                                Découvrir nos questions-réponses
                            </Link>
                        </div>
                    </div>

                    {/* Video Section */}
                    <div className="container pt-0 pb-3">
                        <div className="w-100" style={{ margin: "0 auto" }}>
                            <video controls className="w-100 video-js vjs-16-9 vjs-default-skin rounded"
                                poster="../app.creer-application.com/data/applications/pictures/11090/poster/e0a17ee1976a3c3ab5b126b1ea2b3641.jpg"
                                data-setup='{"controls": true, "autoplay": "false", "preload": "false", "controlBar": { "pictureInPictureToggle": false } }'>
                                <source src="https://app.creer-application.com/app/video/playVideo/11090/2518" type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    {/* Card Section */}
                    <div className="container text-center card pt-4 pb-3 mt-3 mb-4"
                        style={{ position: 'relative', height: '20rem', overflow: 'hidden', backgroundImage: 'url("/data/applications/pictures/rendez-vous.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <div className="w-100">
                            <h3 className="text-light" style={{ marginTop: '6rem', fontWeight: 'bold', color: '#ffff' }}>
                                Échanger avec <span style={{ color: '#269e4a' }}>un personnel de santé</span>
                            </h3>
                        </div>
                    </div>

                    {/* Emergency Numbers */}
                    <div className="container pt-4 pb-5">
                        <div className="card text-dark bg-light">
                            <h2 className="mt-4 mr-2" style={{ color: "#d8ca06", marginLeft: "10px" }}>Best</h2><br />
                            <div className="card-body">
                                <p className="card-text">Maîtriser les numéros d'urgence est crucial pour votre sécurité et celle de vos proches.</p>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="p-3 text-white rounded" style={{ backgroundColor: "#95c43d" }}>
                                            <h5>SAMU</h5>
                                            <p>15</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="p-3 text-white rounded" style={{ backgroundColor: "#ebdb08" }}>
                                            <h5>Police</h5>
                                            <p>17</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="p-3 text-white rounded" style={{ backgroundColor: "#e2d507" }}>
                                            <h5>Pompiers</h5>
                                            <p>18</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="p-3 text-white rounded" style={{ backgroundColor: "#95c43d" }}>
                                            <h5>Numéro d'urgence européen</h5>
                                            <p>112</p>
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-3 text-muted">N'hésitez pas à enregistrer ces numéros dans votre téléphone.</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <Footer />
            </div>
           
        </>
    );
};

export default Home;
