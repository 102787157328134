import React from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/saga-blue/theme.css';  // Thème PrimeReact
import 'primereact/resources/primereact.min.css';          // Styles de base PrimeReact
import 'primeicons/primeicons.css';                        // Icônes PrimeReact
import Footer from '../partials/footer';
import NavBar from '../partials/nav';

const EmergencyNumbers = () => {
    const emergencyData = [
        { service: 'Police', number: '117' },
        { service: 'Pompiers', number: '118' },
        { service: 'Ambulance', number: '119' },
        { service: 'Centre anti-poison', number: '148' },
        { service: 'Numéro vert COVID-19', number: '1510' },
    ];

    const greenNumbers = [
        { service: 'Protection du consommateur', number: '1500' },
        { service: 'Lutte contre la corruption', number: '1550' },
        { service: 'SOS enfants en détresse', number: '113' },
    ];

    // Style pour les cellules
    const cellStyle = { color: '#7b8610', fontWeight: 'bold' };
     // Style pour le header
     const headerStyle = { backgroundColor: '#7b8610', color: '#ffffff' };
    return (
        <div>
        <NavBar/>    
        <div style={{ margin: '2rem' }}>
            <Card title="Numéro d'urgence au Cameroun" className="mb-4">
                <DataTable value={emergencyData} responsiveLayout="scroll">
                    <Column field="service" header="Services" bodyStyle={cellStyle} headerStyle={headerStyle} />
                    <Column field="number" header="Numéro" headerStyle={headerStyle}/>
                </DataTable>
            </Card>

            <Card title="Numéro verts au Cameroun">
                <DataTable value={greenNumbers} responsiveLayout="scroll">
                    <Column field="service" header="Services" headerStyle={headerStyle} bodyStyle={cellStyle}/>
                    <Column field="number" header="Numéro" headerStyle={headerStyle}/>
                </DataTable>
            </Card>
        </div>
        <Footer/>
    </div>
    );
};

export default EmergencyNumbers;
