import React from 'react';
import './ChocolateCard.css';  
import { Link } from 'react-router-dom';
import { baseURL } from '../utils/axiosConfig';

const ChocolateCardFactChecking = ({ article }) => {  // Recevoir l'article comme prop
  return (
    <div className="article-card">
      {/* Image */}
      <div className="image-container">
        <img 
          src={`${baseURL}${article.image_url}`} // Utiliser l'image de l'article ou une image par défaut
          alt={article.titre} 
          className="book-image"
        />
      </div>

      {/* Titre */}
      <h2 className="article-title">
        {article.titre}  {/* Afficher le titre de l'article */}
      </h2>

      {/* Auteur et Date */}
      <div className="meta-info">
        <span className="author">
          <span className="icon-users"></span>
          {article.source}  {/* Assurez-vous que l'article a une propriété 'auteur' */}
        </span>
      </div>
       {/* Auteur et Date */}
       <div className="meta-info">
        <span className="date">
          <span className="icon-calendar"></span>
          {new Date(article.createdAt).toLocaleDateString('fr-FR')}  {/* Formater la date */}
        </span>
      </div>

      {/* Description courte */}
      <p className="description">
        {article.resume}  {/* Afficher le résumé de l'article */}
      </p>

      {/* Bouton CTA */}
      <div className="cta-container">
        <Link
          to={`/healthDetail/${article.id}`}  // Utiliser le lien de l'article ou un lien par défaut
          // target="_blank" 
          rel="noopener noreferrer" 
          className="cta-button"
          style={{backgroundColor:'#d8b12f'}}
        >
          Lire l’article
        </Link>
        
      </div>
    </div>
  );
};
export default ChocolateCardFactChecking;
