import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { useNavigate } from 'react-router-dom';
import HeaderAdmin from '../partials/header-admin';
import AdminFooter from '../partials/admin-footer';

const QuestionsPage = () => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    const navigate = useNavigate(); // Utilisez useNavigate pour la redirection

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await axiosInstance.get('/questions/');
                setQuestions(response.data);
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec du chargement des questions', life: 3000 });
            } finally {
                setLoading(false);
            }
        };

        fetchQuestions();
    }, []);

    const handleActionClick = async (action, question) => {
        if (action === 'edit') {
            console.log('Edit', question);
          
            navigate(`/admin-questionupdate/${question.id}/`);
            
        } else if (action === 'delete') {
            confirmDialog({
                message: 'Êtes-vous sûr de vouloir supprimer cette question?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    try {
                        await axiosInstance.delete(`/question/${question.id}/`,
                        {
                            headers:{
                                Authorization: `Bearer ${localStorage.getItem("token")}`
                            }
                        });

                        setQuestions(questions.filter(q => q.id !== question.id));
                        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Question supprimée', life: 3000 });
                    } catch (error) {
                        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Échec de la suppression de la question', life: 3000 });
                    }
                },
                reject: () => {
                    toast.current.show({ severity: 'info', summary: 'Annulé', detail: 'Suppression annulée', life: 3000 });
                }
            });
        }
    };

    const actionTemplate = (rowData) => (
        <Dropdown
            options={[
                { label: 'Modifier', value: 'edit' },
                { label: 'Supprimer', value: 'delete' }
            ]}
            onChange={(e) => handleActionClick(e.value, rowData)}
            placeholder="Actions"
            className="p-dropdown"
        />
    );

    const optionsBodyTemplate = (rowData) => {
        if (Array.isArray(rowData.options)) {
            return rowData.options.join(', ');
        }
        return rowData.options; // Assurez-vous que `options` peut être affiché tel quel s'il n'est pas un tableau
    };

    return (
        <>
             <div className="app-wrapper">
        <HeaderAdmin />
            <div id="main" style={{ height: '100vh' }}>
                <section className="pt-3 pt-md-5 ms-auto me-auto pb-3 pb-md-5" style={{ maxWidth: '1200px' }}>
                    <div className="container">
                        <div className="card bg-light text-dark" style={{ borderRadius: '8px' }}>
                            <div className="card-body p-4 p-md-5 text-center">
                                <h1 className="h2 fw-bold mb-4">Questions</h1>
                                <DataTable value={questions} loading={loading} paginator rows={10} responsiveLayout="scroll">
                                    <Column field="question" header="Question" />
                                    <Column field="options" header="Options" body={optionsBodyTemplate} />
                                    <Column field="type" header="Type" />
                                    <Column header="Actions" body={actionTemplate} />
                                </DataTable>
                                <Toast ref={toast} />
                                <ConfirmDialog />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AdminFooter />
            </div>
        </>
    );
};

export default QuestionsPage;
