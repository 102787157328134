import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import Footer from '../partials/footer'; // Assurez-vous que ces composants existent
import NavBar from '../partials/nav'; // Assurez-vous que ces composants existent
import axios, { baseURL } from './../utils/axiosConfig'; // Importez axios depuis votre configuration
import './AcceptedAppointments.css'; // Fichier CSS personnalisé pour le style
import { useNavigate } from 'react-router-dom'; // Importez useNavigate

const AcceptedAppointments = () => {
    const [expandedAppointmentId, setExpandedAppointmentId] = useState(null);
    const [currentPage, setCurrentPage] = useState(0); // Page actuelle
    const [appointmentsPerPage] = useState(6); // Nombre de rendez-vous par page
    const [acceptedAppointments, setAcceptedAppointments] = useState([]); // État pour stocker les rendez-vous acceptés
    const navigate = useNavigate(); // Hook pour naviguer

    // Utilisez useEffect pour récupérer les rendez-vous lorsque le composant se monte
    useEffect(() => {
        fetchAcceptedAppointments();
    }, []);

    // Fonction pour rediriger vers la discussion
    const handleStartDiscussion = (appointmentId) => {
        // Redirige vers la page de discussion avec l'ID de rendez-vous
        navigate(`/discussion/${appointmentId}`);
    };

    const fetchAcceptedAppointments = async () => {
        try {
            const response = await axios.get('/rendezvous_valide/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}` // Assurez-vous que le token est stocké dans le localStorage ou ailleurs
                }
            });
            // Filtrer les rendez-vous où l'état est true
            const filteredAppointments = response.data.filter(appointment => appointment.etat === true);
            setAcceptedAppointments(filteredAppointments); // Mettre à jour l'état avec les rendez-vous acceptés
        } catch (error) {
            console.error('Erreur lors de la récupération des rendez-vous acceptés:', error);
        }
    };

    const toggleDescription = (id) => {
        setExpandedAppointmentId(expandedAppointmentId === id ? null : id);
    };

    const renderAcceptedAppointmentCard = (appointment) => {
        const isExpanded = expandedAppointmentId === appointment.id;
        const status = appointment.etat ? 'Validé' : 'En attente'; // Mettez le calcul de status ici

        // Récupérer l'ID du médecin depuis le localStorage
        const medecinData = JSON.parse(localStorage.getItem('user_informations')); // Assurez-vous que les données existent
        const medecinid = medecinData ? medecinData.id : null; // Si l'objet est valide, prenez l'ID
        
        return (
            <Card key={appointment.id} className="accepted-appointment-card">
                <div className="card-content">
                    <div className="d-flex w-100" style={{ justifyContent: 'space-between' }}>
                        <Avatar image={baseURL+  appointment.Patient?.Utilisateur?.photo || 'https://randomuser.me/api/portraits/men/1.jpg'} shape="circle" size="large" className="avatar" />
                        <div className="appointment-info">
                            <p className="patient-name">Nom : {appointment.Patient?.Utilisateur ? `${appointment.Patient.Utilisateur.nom} ${appointment.Patient.Utilisateur.prenom}` : 'Nom non disponible'}</p>
                            <p className="ailment">Type : {appointment.type}</p>
                            <p className="date-time">Date : {new Date(appointment.date_heure).toLocaleDateString('fr-FR')}</p>
                            <p className="time">Durée : {new Date(appointment.date_heure).toLocaleTimeString('fr-FR')} - {new Date(appointment.date_heure_fin).toLocaleTimeString('fr-FR')}</p>
                            <p className="succes" style={{color:'#5bb57c'}}>Statut : {status}</p>
                        </div>
                    </div>
                    <div className={`description ${isExpanded ? 'expanded' : 'collapsed'}`}>
                        {appointment.notes}
                    </div>
                    <Button
                        icon={isExpanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
                        className="p-button-text toggle-button d-flex justify-content-end"
                        onClick={() => toggleDescription(appointment.id)}
                        tooltip={isExpanded ? "Réduire" : "Afficher plus"}
                        tooltipOptions={{ position: 'top' }}
                    />
                    <Button
                        label="Démarrer discussion"
                        icon="pi pi-info"
                        className="p-button-info mt-2"
                        onClick={() => handleStartDiscussion(medecinid)} // Utilisez la fonction de redirection ici
                    />
                </div>
            </Card>
        );
    };

    // Calcule les rendez-vous à afficher en fonction de la page actuelle
    const indexOfLastAppointment = (currentPage + 1) * appointmentsPerPage;
    const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
    const currentAppointments = acceptedAppointments.slice(indexOfFirstAppointment, indexOfLastAppointment);

    // Gestion de la pagination
    const onPageChange = (event) => {
        setCurrentPage(event.first / appointmentsPerPage);
    };

    return (
        <>
            <NavBar />
            <div className="accepted-appointments-container">
                <h3 className='apropos' style={{ fontWeight:'bold', color:'#ffa500', marginLeft: '2rem', marginBottom: '3rem', marginTop: '9rem' }}>Rendez-vous Validé</h3>
                <div className="accepted-appointments-grid">
                    {currentAppointments.map(renderAcceptedAppointmentCard)}
                </div>
                <Paginator
                    first={currentPage * appointmentsPerPage}
                    rows={appointmentsPerPage}
                    totalRecords={acceptedAppointments.length}
                    onPageChange={onPageChange}
                    template="PrevPageLink PageLinks NextPageLink"
                    className="custom-paginator"
                />
            </div>
            <Footer />
        </>
    );
};

export default AcceptedAppointments;
